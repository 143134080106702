export const TABS = {
	TITLE: 'title',
	CONFIG: 'config',
	CONTENT: 'content',
};

export const STATUSES = ['new', 'generating', 'generated', 'editor review', 'editor approved', 'pending client approval', 'client approved', 'published'];

export const STATUS_MAP = {
	NEW: 'new',
	GENERATING: 'generating',
	GENERATED: 'generated',
	EDITOR_REVIEW: 'editor review',
	EDITOR_APPROVED: 'editor approved',
	PENDING_CLIENT_APPROVAL: 'pending client approval',
	CLIENT_APPROVED: 'client approved',
	PUBLISHED: 'published',
};

export const STATUS_COLORS = {
	[STATUS_MAP.NEW]: 'gray-300',
	[STATUS_MAP.GENERATING]: 'warning-light',
	[STATUS_MAP.GENERATED]: 'primary-dark',
	[STATUS_MAP.EDITOR_REVIEW]: 'dark',
	[STATUS_MAP.EDITOR_APPROVDED]: 'info',
	[STATUS_MAP.PENDING_CLIENT_APPROVAL]: 'warning',
	[STATUS_MAP.CLIENT_APPROVED]: 'success-dark',
	[STATUS_MAP.PUBLISHED]: 'success',
};

export const ARTICLE_TYPE_MAP = {
	AUTO: 'auto',
};
