import React from "react";
import Meta from "components/Meta";
import ArticlesSection from "components/ArticlesSection";
import { requireAuth } from "util/auth";

function ArticlesPage(props) {
  return (
    <>
      <Meta title="Articles" />
      <ArticlesSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Articles"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(ArticlesPage);
