import React, { useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from'dayjs/plugin/relativeTime';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import SearchInput from 'components/SearchInput';
import SpinnerCenter from 'components/SpinnerCenter';
import UserRow from 'components/UsersTable/UserRow';
import {
	useGetUsersQuery,
	useGetOrganizationsQuery,
} from 'api/client';
dayjs.extend(relativeTime);

export default function UsersTable(props) {
	const [search, setSearch] = useState('');

	const {
  	data: users = [],
  	isLoading: usersIsLoading,
  	refetch: usersRefetch,
  	error,
  } = useGetUsersQuery({ search });

  const {
  	data: organizations = [],
  } = useGetOrganizationsQuery();

  return usersIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
  	<Card className="UsersTable shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
	  			<SearchInput
	  				placeholder="Search by name or email"
	  				setSearch={setSearch}
	  				debounceTime={250}
	  			/>
				</Flex>
  		</Card.Header>
  		<Card.Body className="p-0 scroll-x">
  			{users?.length > 0 ? (
  				<Table size="sm" className="responsive table-stripe">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Organization</th>
								<th>Role</th>
							</tr>
						</thead>
						<tbody>
							{users?.map?.(user => (
								<UserRow
									key={user.id}
									user={user}
									organizations={organizations}
									usersRefetch={usersRefetch}
								/>
							))}
						</tbody>
					</Table>
  			) : (
  				<Flex justify="center" className="text-danger py-2">
  					{error?.data?.message ? (
  						<span>{error?.data?.message || 'An unknown error occured'}</span>
  					) : (
							<span>No users found{search && ' matching your search'}</span>
  					)}
					</Flex>
  			)}
  		</Card.Body>
		</Card>
  );
};
