import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TOOLBAR_MODULES = [
	[{ 'header': [1, 2, false] }],
  ['bold', 'italic', 'underline','strike'],
  ['blockquote', 'code-block'],
  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
  ['link', 'image'],
  ['clean']
];

// All - enabled by default
const FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

function Editor(props, ref) {
	const quillRef = useRef();

	useImperativeHandle(ref, () => ({
		quill: quillRef.current,
	}));

	return (
		<div
			ref={ref}
			className="Editor"
		>
			<ReactQuill
				ref={quillRef}
				className="Editor-Root"
				theme="snow"
				modules={{ toolbar: TOOLBAR_MODULES }}
				formats={FORMATS}
				value={props.editorContent}
				onChange={props.setEditorContent}
				readOnly={props.isReadOnly}
			/>
		</div>
	);
};

export default forwardRef(Editor);
