import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import ArticlesTable from "components/ArticlesTable";

function ArticlesSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid className="px-lg-4 px-xl-5" style={{maxWidth: '1600px'}}>
        <SectionHeader
          title={props.title || "Articles"}
          size={3}
          spaced={true}
          className="text-center"
        />
        <ArticlesTable />
      </Container>
    </Section>
  );
}

export default ArticlesSection;
