import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import StatusBadge from './StatusBadge';
import { useRemoveArticleMutation } from 'api/client';
import { useHistory } from 'util/router';
import { truncateString } from 'util/strings';

function InfoCard(props) {
	return (
		<Card className="OrganizationInfoCard shadow">
			<CardHeader {...props} />
			<CardBody {...props} />
			<CardFooter id={props.id} />
		</Card>
	);
};

export default InfoCard;


const CardHeader = (props) => (
	<Card.Header>
		<Flex
			justify="between"
			align="center"
		>
			<span className="fw5 pr-2">Status</span>
			<StatusBadge
				isEditable={true}
				articleId={props.id}
				status={props.status}
				refetch={props.refetch}
			/>
		</Flex>
	</Card.Header>
);

const CardBody = (props) => (
	<Card.Body>
		<Flex className="line-item-wrapper">
			<Flex className="item-wrapper text-truncate" title={props.organization?.name}>
				<span className="label">Organization</span>
				<span className="value text-truncate">
					<Button
						variant="link"
						className="p-0"
						onClick={(e) => {
							e.stopPropagation();
							props.navigateToOrganization(props.organization?.id);
						}}
					>
						{truncateString(props.organization?.name, 32, '-')}
					</Button>
				</span>
			</Flex>
		</Flex>
	</Card.Body>
);

const CardFooter = ({id}) => {
	const { goBack } = useHistory();
  const [removeArticle, removeArticleResult] = useRemoveArticleMutation();

  useEffect(() => {
		if (removeArticleResult.isError) {
			console.error('useRemoveArticleMutation error', removeArticleResult.error);
		}

		if (removeArticleResult.isSuccess) {
			goBack();
		}
	}, [
		removeArticleResult,
		goBack
	]);

	return (
		<Card.Footer>
			<Flex justify="between" align="center">
				<span className="f-rem-0.85 fw5 text-gray-600">Remove article</span>
				<Button
					title="Remove article"
					variant="outline-danger"
					size="sm"
					className="py-0"
					disabled={removeArticleResult.isLoading}
					onClick={e => {
						e.stopPropagation();

						if (
							window.confirm('Are you sure? This cannot be undone.')
						) {
							removeArticle({ id });
						}
					}}
				>
					{removeArticleResult.isLoading ? <Spinner /> : <FontAwesomeIcon icon={faTrashCan} />}
				</Button>
			</Flex>
		</Card.Footer>
	);
};
