import React, { useState, useEffect, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import Spinner from 'components/Spinner';
import OverwriteTitle from './OverwriteTitle';
import {
	useGenerateArticleTitleOptionsMutation,
	useUpdateArticleMutation
} from 'api/client';
import { getDefaultError } from 'util/error';
import { titleGeneratorReducerInitialState, titleGeneratorReducer } from './reducers';

function TitleGenerator(props) {
	const { isGeneratingContent, refetch, onSetTitle } = props;

	const [
		{
			title = '',
			keywords = [],
			targetPersonas = [],
			extraNotes = '',
		},
		dispatchConfig
	] = useReducer(titleGeneratorReducer, titleGeneratorReducerInitialState);

	const [titleSuggestions, setTitleSuggestions] = useState([]);
	const [isSettingSelectedTitle, setIsSettingSelectedTitle] = useState(false);

	// Mutations
	const [generateTitleOptions, generateTitleOptionsResult] = useGenerateArticleTitleOptionsMutation();

	useEffect(() => {
		const { error, isError, isSuccess } = generateTitleOptionsResult;
		if (isError) console.error('useGenerateArticleTitleOptionsMutation error', error);
		if (isSuccess) {
			setTitleSuggestions(generateTitleOptionsResult.data);
			setTimeout(() => generateTitleOptionsResult.reset(), 750);
		}
	}, [generateTitleOptionsResult]);

	const [updateArticle, updateArticleResult] = useUpdateArticleMutation();

	useEffect(() => {
		const { error, isError, isSuccess } = updateArticleResult;
		if (isError) console.error('useUpdateArticleMutation error', error);

		if (isSuccess) {
			typeof refetch === 'function' && refetch();
			setTimeout(onSetTitle, 750);

			if (isSettingSelectedTitle) {
				setIsSettingSelectedTitle(false);

				setTimeout(() => {
					setTitleSuggestions([]);
					generateTitleOptionsResult.reset();
				}, 750);
			}
		}

		if (isError || isSuccess) {
			setTimeout(() => updateArticleResult.reset(), 750);
		}
	}, [
		updateArticleResult,
		refetch,
		isSettingSelectedTitle,
		generateTitleOptionsResult,
		onSetTitle,
	]);

	return (
		<Card className="TitleGenerator shadow">
			<CardHeader
				title={props.title}
				isLoading={updateArticleResult.isLoading || generateTitleOptionsResult.isLoading}
			/>
			{titleSuggestions.length < 1 ? (
				<CardBody
					{...props}
					overwriteTitle={title}
					keywords={keywords}
					orgKeywords={props.keywords}
					targetPersonas={targetPersonas}
					orgTargetPersonas={props.targetPersonas}
					extraNotes={extraNotes}
					dispatch={dispatchConfig}
					isGeneratingContent={isGeneratingContent}
					updateArticle={updateArticle}
					updateArticleResult={updateArticleResult}
					generateTitleOptionsResult={generateTitleOptionsResult}
					refetch={props.refetch}
				/>
			) : (
				<SelectTitleOption
					titleSuggestions={titleSuggestions}
					isGenerating={generateTitleOptionsResult.isLoading}
					updateArticle={(selectedTitle) => {
						setIsSettingSelectedTitle(true);
						updateArticle({ id: props.id, title: selectedTitle })
					}}
					updateArticleResult={updateArticleResult}
				/>
			)}
			<CardFooter
				hasTitleSuggestions={titleSuggestions.length > 0}
				isGeneratingContent={isGeneratingContent}
				updateArticleResult={updateArticleResult}
				generateTitleOptions={() => {
					generateTitleOptionsResult.reset();

					generateTitleOptions({
						orgName: props.orgName,
						brandInfo: props.brandInfo,
						brandGuidelines: props.brandGuidelines,
						keywords: keywords.join('. '),
						targetPersonas: targetPersonas.join('. '),
						notes: extraNotes,
					});
				}}
				generateTitleOptionsResult={generateTitleOptionsResult}
			/>
		</Card>
	);
};

export default TitleGenerator;


const CardHeader = (props) => {
	return (
		<Card.Header>
			<span
				className={classnames('f-rem-1 fw5', {
					'text-warning': !props.title
				})}
			>
				{props.title || 'Title not generated'}{props.isLoading && <Spinner className="ml-2 text-gray-500"/>}
			</span>
		</Card.Header>
	);
};

const CardBody = (props) => {
	const {
		id,
		title,
		overwriteTitle,
		keywords = [],
		orgKeywords = [],
		targetPersonas = [],
		orgTargetPersonas = [],
		extraNotes,
		updateArticle,
		updateArticleResult,
		generateTitleOptionsResult,
		dispatch,
		isGeneratingContent,
	} = props;

	const isLoading = isGeneratingContent || updateArticleResult.isLoading || generateTitleOptionsResult.isLoading;
	const isSuccess = updateArticleResult.isSuccess || generateTitleOptionsResult.isSuccess;
	const TABS = ['generate', 'manual'];
	const [tab, setTab] = useState(TABS[0]);

	return (
		<Card.Body>
			<Toggle
				ops={TABS}
				active={tab}
				setActive={setTab}
				disabled={isLoading || isSuccess || updateArticleResult.isError}
				className="w-fit"
			/>
			<hr className="mt-3 mb-2" />

			{tab === 'generate' ? (
				<>
					{/*Keywords*/}
					{orgKeywords?.length > 0 && (
						<Toggle
							label="Select keywords for title generation"
							labelClassName="label d-block mb-1"
							ops={orgKeywords?.map(k => k.title)}
							active={keywords}
							setActive={op => dispatch({ type: 'keywords', payload: op })}
							disabled={isLoading || isSuccess || updateArticleResult.isError}
							className="w-fit mb-3 scroll-x"
							opClassName="f-rem-0.9"
						/>
					)}
					{/*Target Personas*/}
					{orgTargetPersonas?.length > 0 && (
						<Toggle
							label="Select target personas for title generation"
							labelClassName="label d-block mb-1"
							ops={orgTargetPersonas?.map(k => k.title)}
							active={targetPersonas}
							setActive={op => dispatch({ type: 'targetPersonas', payload: op })}
							disabled={isLoading || isSuccess || updateArticleResult.isError}
							className="w-fit mb-3"
							opClassName="f-rem-0.9"
						/>
					)}
					{/*Extra Notes*/}
					<Form.Group className="mb-3">
						<Form.Label className="label mb-0.5">Extra notes for title generation</Form.Label>
						<Form.Control
							type="text"
							placeholder="Extra notes"
							value={extraNotes}
							readOnly={isLoading || isSuccess || updateArticleResult.isError}
							onChange={e => dispatch({ type: 'extraNotes', payload: e.target.value })}
						/>
					</Form.Group>
				</>
			) : (
				<OverwriteTitle
					id={id}
					title={title}
					overwriteTitle={overwriteTitle}
					dispatch={title => dispatch({ type: 'title', payload: title })}
					updateArticle={updateArticle}
					updateArticleResult={updateArticleResult}
					generateTitleOptionsResult={generateTitleOptionsResult}
				/>
			)}
		</Card.Body>
	);
};

const CardFooter = (props) => {
	const {
		hasTitleSuggestions,
		isGeneratingContent,
		updateArticleResult,
		generateTitleOptions,
		generateTitleOptionsResult,
	} = props;

	const [progress, setProgress] = useState(0);
	const isLoading = isGeneratingContent || updateArticleResult.isLoading || generateTitleOptionsResult.isLoading;
	const isSuccess = updateArticleResult.isSuccess || generateTitleOptionsResult.isSuccess;
	const isError = updateArticleResult.isError || generateTitleOptionsResult.isError;
	const generatingTitlesIsLoading = generateTitleOptionsResult.isLoading;

	useEffect(() => {
		let interval;

		if (generatingTitlesIsLoading) {
			interval = setInterval(
				() => setProgress(
					prev => prev >= 100 ? 100 : prev + 1
				), 400 //takes 40s to get to 100
			);
		} else {
			setProgress(0);
		}

		return () => clearInterval(interval);
	}, [generatingTitlesIsLoading]);

	return (
		<Card.Footer>
			<Flex direction="column">
				<Flex>
					<Button
						variant={generateTitleOptionsResult.isSuccess ? 'success' : generateTitleOptionsResult.isError ? 'danger' : hasTitleSuggestions ? 'info-dark' : 'primary'}
						size="sm"
						disabled={isLoading || isSuccess || isError}
						onClick={generateTitleOptions}
						className="w-fit"
					>
						{isGeneratingContent || generateTitleOptionsResult.isLoading ? (
							<><Spinner className="mr-2" /><span>{isGeneratingContent ? 'Article is generating' : 'Generating'}</span></>
						) : generateTitleOptionsResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : generateTitleOptionsResult.isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : hasTitleSuggestions ? 'Regen Title Options' : 'Gen Title Options'}
					</Button>
					{generateTitleOptionsResult.isError && (
						<Button
							variant="link"
							size="sm"
							disabled={isLoading}
							onClick={() => generateTitleOptionsResult.reset()}
							className="ml-2 text-warning"
						>
							Reset
						</Button>
					)}
				</Flex>
				{generateTitleOptionsResult.isLoading ? (
					<ProgressBar
						variant="success"
						animated
						now={progress}
						className="mt-1.5"
						style={{height: '.3rem'}}
					/>
				) : generateTitleOptionsResult.isError ? (
					<span className="f-rem-0.9 fw5 text-danger mt-2">{getDefaultError(generateTitleOptionsResult.error)}</span>
				) : null}
			</Flex>
		</Card.Footer>
	);
};

const SelectTitleOption = (props) => {
	const {
		titleSuggestions = [],
		isGenerating,
		updateArticle,
		updateArticleResult,
	} = props;

	const { isError, isSuccess, isLoading } = updateArticleResult;

	return (
		<Card.Body className="SelectTitleOption">
			<Flex direction="column">
				{titleSuggestions.map(title => (
					<Flex
						key={title}
						justify="between"
						align="center"
						className="TitleOption"
					>
						<span>{title}</span>
						<Button
							variant={updateArticleResult?.originalArgs?.title !== title ? 'primary' : isSuccess ? 'success' : isError ? 'danger' : 'primary'}
							size="sm"
							disabled={isGenerating || isLoading || isSuccess || isError}
							onClick={() => updateArticle(title)}
							className="w-fit ml-3 text-nowrap py-0.5"
						>
							{updateArticleResult?.originalArgs?.title !== title ? 'Set title' : isLoading ? (
								<Spinner />
							) : isSuccess ? (
								<FontAwesomeIcon icon={faCheck} />
							) : isError ? (
								<FontAwesomeIcon icon={faTimes} />
							) : 'Set title'}
						</Button>
					</Flex>
				))}
			</Flex>
		</Card.Body>
	);
};
