import React from "react";
import Meta from "components/Meta";
import User from "components/User";

function UserPage(props) {
  return (
    <>
      <Meta title="User" />
      <User
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="User"
      	subtitle=""
      />
    </>
  );
}

export default UserPage;
