import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import './BackBtn.scss';

function BackBtn(props) {
	return (
		<div
			className={classnames('BackBtn', {
				...props.className ? {
					[props.className]: true
				} : {}
			})}
			{...typeof props.onClick === 'function' ? {
				onClick: props.onClick
			} : {}}
		>
			<FontAwesomeIcon icon={faChevronLeft} className="icon"/>
			<span className="text">Back</span>
		</div>
	);
};

export default BackBtn;
