import React, { useState, useReducer, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Flex from 'components/Flex';
import FormField from 'components/FormFieldCustom';
import FormMultiField from 'components/FormMultiField';
import Popup from 'components/Popup';
import Spinner from 'components/Spinner';
import SpinnerCenter from 'components/SpinnerCenter';
import {
	// Queries
	useGetOrganizationsQuery,
	// Mutations
	useUpsertOrganizationMutation
} from 'api/client';
import { getDefaultError } from 'util/error';
import { keywordsAndPersonasReducer } from './reducers';
import './EditOrganizationPopup.scss';

const generateId = () => Math.floor((Math.random()*100000000));

function EditOrganizationPopup(props) {
	const { id: organizationId } = props;
	const popupRef = useRef();
	const formRef = useRef();
	const [validated, setValidated] = useState(false);

	// Queries
	const {
  	data: organizations = [],
  	isLoading: organizationsIsLoading,
  	refetch: organizationsRefetch,
  } = useGetOrganizationsQuery({
  	id: organizationId
  }, {
  	skip: !organizationId,
  });

  const [organization = {}] = organizations;

  // Set up local version of keywords and target personas
  const [keywords, dispatchKeywords] = useReducer(keywordsAndPersonasReducer, []);
  const [targetPersonas, dispatchTargetPersonas] = useReducer(keywordsAndPersonasReducer, []);

  // Initialize keywords/target personas onload
  useEffect(() => {
  	if (!organizationsIsLoading) {
  		organization.keywords?.map(
  			k => dispatchKeywords({
	  			type: 'PUSH',
	  			payload: {
	  				...k,
	  				id: generateId(),
		  		},
	  		})
	  	);

	  	organization.targetPersonas?.map(
  			tp => dispatchTargetPersonas({
	  			type: 'PUSH',
	  			payload: {
	  				...tp,
	  				id: generateId(),
		  		},
	  		})
	  	);
  	}
  // eslint-disable-next-line
  }, [organizationsIsLoading]);

  // Mutations
	const [upsertOrg, upsertOrgResult] = useUpsertOrganizationMutation();

	useEffect(() => {
		const { error, isError, isSuccess } = upsertOrgResult;
		if (isError) console.error('useUpsertOrganizationMutation error', error);

		if (isSuccess) {
			if (organizationId) {
				organizationsRefetch();
			}

			setTimeout(popupRef.current.closePopup, 750);
		}
	}, [
		upsertOrgResult,
		organizationId,
		organizationsRefetch
	]);

	// States
  const isReadOnly = upsertOrgResult.isLoading || upsertOrgResult.isSuccess;

	// Callbacks
	const handleSubmit = useCallback((e) => {
		e.preventDefault();
		const form = e.currentTarget;

		if (form.checkValidity() === false) {
    	setValidated(true);
      // Exit submission
    } else {
    	upsertOrgResult.reset();

    	const params = {
    		...organizationId ? {
    			id: organizationId
    		} : {},
    		name: form.name.value.trim(),
    		website: form.website.value.trim(),
    		brandInfo: form.brandInfo.value.trim(),
    		brandGuidelines: form.brandGuidelines.value.trim(),
    		valueProp: form.valueProp.value.trim(),
    		keywordsDocLink: form.keywordsDocLink.value.trim(),
    		targetPersonasDocLink: form.targetPersonasDocLink.value.trim(),
    		keywords: keywords.map(({ id }) => ({
    			title: form[`keyword-title-${id}`].value.trim(),
    			description: form[`keyword-description-${id}`].value.trim(),
    		})),
    		targetPersonas: targetPersonas.map(({ id }) => ({
    			title: form[`targetPersona-title-${id}`].value.trim(),
    			description: form[`targetPersona-description-${id}`].value.trim(),
    		})),
    	};

    	upsertOrg(params);
    }
	}, [
		organizationId,
		keywords,
		targetPersonas,
		upsertOrg,
		upsertOrgResult
	]);

	const handleOnChange = useCallback(() => {
		setValidated(false);
		upsertOrgResult.reset();
	}, [upsertOrgResult]);

	return (
		<Popup
			ref={popupRef}
			className="EditOrganizationPopup"
			open={true}
			title={organizationId ? `Edit ${organization.name}` : 'Create new organization'}
			loading={organizationsIsLoading}
			size="lg"
			afterClose={props.afterClose}
			preventClose={upsertOrgResult.isLoading}
		>
			{organizationsIsLoading ? (<SpinnerCenter wrapperClassName="py-5" size="md" />) : (
				<Form
					ref={formRef}
					className="p-3"
					noValidate
					validated={validated}
					onSubmit={handleSubmit}
				>
					<Tabs defaultActiveKey="general" transition={false}>
						<Tab eventKey="general" title="General">
							<Flex direction="column" className="mt-2">
								{/*Name*/}
								<FormField
									label="Name"
									type="text"
									placeholder="Org name"
									defaultValue={organization.name}
									required={true}
									onChange={handleOnChange}
									readOnly={isReadOnly}
									invalidFeedback="Name is required"
								/>
								{/*Website*/}
								<FormField
									label="Website"
									type="text"
									placeholder="Org's website"
									defaultValue={organization.website}
									onChange={handleOnChange}
									readOnly={isReadOnly}
								/>
								{/*Brand info*/}
								<FormField
									id="brandInfo"
									label="Brand information"
									type="textarea"
									placeholder="Org's brand information"
									defaultValue={organization.brandInfo}
									onChange={handleOnChange}
									readOnly={isReadOnly}
								/>
								{/*Brand guidelines*/}
								<FormField
									id="brandGuidelines"
									label="Brand guidelines"
									type="textarea"
									placeholder="Org's brand guidelines"
									defaultValue={organization.brandGuidelines}
									onChange={handleOnChange}
									readOnly={isReadOnly}
								/>
								{/*Value proposition*/}
								<FormField
									id="valueProp"
									label="Value proposition"
									type="textarea"
									placeholder="Org's value proposition"
									defaultValue={organization.valueProp}
									onChange={handleOnChange}
									readOnly={isReadOnly}
								/>
								{/*Keywords doc link*/}
								<FormField
									id="keywordsDocLink"
									label="Keywords doc link"
									type="text"
									placeholder="Link to keywords doc"
									defaultValue={organization.keywordsDocLink}
									onChange={handleOnChange}
									readOnly={isReadOnly}
								/>
								{/*Target personas doc link*/}
								<FormField
									id="targetPersonasDocLink"
									label="Target personas doc link"
									type="text"
									placeholder="Link to target personas doc"
									defaultValue={organization.targetPersonasDocLink}
									onChange={handleOnChange}
									readOnly={isReadOnly}
								/>
							</Flex>
						</Tab>

						<Tab
							eventKey="keywords"
							title={(
								<Flex align="center">
									<span>Keywords ({keywords.length})</span>
									<Button
										size="sm"
										className="ml-1.5 px-1.5 py-0"
										onClick={() => dispatchKeywords({
											type: 'PUSH',
											payload: {
												id: generateId(),
											}
										})}
									>
										<FontAwesomeIcon icon={faPlus}/>
									</Button>
								</Flex>
							)}
						>
							<Flex direction="column" className="mt-2">
								<FormMultiField
									items={keywords}
									type="keyword"
									label="Keyword"
									onChange={handleOnChange}
									isReadOnly={isReadOnly}
									removeItem={(id) => dispatchKeywords({
										type: 'REMOVE',
										payload: id,
									})}
								/>
							</Flex>
						</Tab>

						<Tab
							eventKey="targetPersonas"
							title={(
								<Flex align="center">
									<span>Target Personas ({targetPersonas.length})</span>
									<Button
										size="sm"
										className="ml-1.5 px-1.5 py-0"
										onClick={() => dispatchTargetPersonas({
											type: 'PUSH',
											payload: {
												id: generateId(),
											}
										})}
									>
										<FontAwesomeIcon icon={faPlus}/>
									</Button>
								</Flex>
							)}
						>
							<Flex direction="column" className="mt-2">
								<FormMultiField
									items={targetPersonas}
									type="targetPersona"
									label="Target Persona"
									onChange={handleOnChange}
									isReadOnly={isReadOnly}
									removeItem={(id) => dispatchTargetPersonas({
										type: 'REMOVE',
										payload: id,
									})}
								/>
							</Flex>
						</Tab>
					</Tabs>

					<Flex justify="end" className="mt-3">
						<Button
							variant="outline-secondary"
							size="sm"
							className="mr-2"
							onClick={() => popupRef.current.closePopup()}
							disabled={upsertOrgResult.isLoading || upsertOrgResult.isSuccess}
						>
							Cancel
						</Button>
						<Button
							variant={upsertOrgResult.isSuccess ? 'success' : 'primary'}
							size="sm"
							type="submit"
							disabled={upsertOrgResult.isLoading || upsertOrgResult.isSuccess}
						>
							{upsertOrgResult.isLoading ? (
								<Spinner />
							) : upsertOrgResult.isSuccess ? (
								<FontAwesomeIcon icon={faCheck} />
							) : 'Save'}
						</Button>
					</Flex>
				</Form>
			)}

			{upsertOrgResult.isError && (
				<Alert variant="danger" className="mb-0">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(upsertOrgResult?.error)}</span>
					</Flex>
				</Alert>
			)}
		</Popup>
	);
};

export default EditOrganizationPopup;
