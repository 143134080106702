import React from "react";
import Meta from "components/Meta";
import OrganizationsSection from "components/OrganizationsSection";
import { requireAuth } from "util/auth";

function OrganizationsPage(props) {
  return (
    <>
      <Meta title="Organizations" />
      <OrganizationsSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Organizations"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(OrganizationsPage);
