import queryConstructor from 'util/queryConstructor';
import { keepUnusedDataFor } from './constants';

function getOrganizations({ id, search } = {}) {
	const queryParams = queryConstructor({id, search});
	return `fetchOrganizations?${queryParams}`;
};

function getOrganizationPublic({ id } = {}) {
	const queryParams = queryConstructor({id});
	return `fetchOrganizationPublic?${queryParams}`;
};

// Mutations
function upsertOrganization(orgInfo = {}) {
	return {
		url: `upsertOrganization`,
		method: 'POST',
		body: orgInfo,
	};
};

function removeOrganization({ id } = {}) {
	return {
		url: `removeOrganization`,
		method: 'DELETE',
		body: { id },
	};
};

// Handlers
export const handlers = (builder) => ({
	getOrganizations: builder.query({
		query: getOrganizations,
		keepUnusedDataFor: keepUnusedDataFor,
	}),

	getOrganizationPublic: builder.query({
		query: getOrganizationPublic,
		keepUnusedDataFor: keepUnusedDataFor,
	}),

	// Mutations
	upsertOrganization: builder.mutation({
		query: upsertOrganization,
	}),

	removeOrganization: builder.mutation({
		query: removeOrganization,
	}),
});

export default handlers;
