import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import { commaFormat } from 'util/numbers';
import { truncateString } from 'util/strings';

const MAX_LENGH_STR = 32;

function OrganizationRow(props) {
	return (
		<tr
			onClick={() => props.navigateToOrganization(props.id)}
		>
			<td>
				<span>{truncateString(props.name, MAX_LENGH_STR, '-')}</span>
			</td>
			<td>
				<a
					href={props.website ? /\/\//.test(props.website) ? props.website : `//${props.website}` : ''}
					target="_blank"
					rel="noreferrer"
					onClick={e => e.stopPropagation()}
				>
					{truncateString(props.website, MAX_LENGH_STR, '-')}
				</a>
			</td>
			<td title={props.brandInfo}>
				<span>{truncateString(props.brandInfo, MAX_LENGH_STR, '-')}</span>
			</td>
			<td title={props.brandGuidelines}>
				<span>{truncateString(props.brandGuidelines, MAX_LENGH_STR, '-')}</span>
			</td>
			<td title={props.valueProp}>
				<span>{truncateString(props.valueProp, MAX_LENGH_STR, '-')}</span>
			</td>
			<td>
				<span>{commaFormat(props.articles?.length, 0, '0')}</span>
			</td>
			<td>
				<Flex>
					<Button
						title="Edit organization"
						variant="outline-primary"
						size="sm"
						className="py-0"
						onClick={e => {
							e.stopPropagation();
							props.editOrganization();
						}}
					>
						<FontAwesomeIcon icon={faPenToSquare} />
					</Button>
					<Button
						title="Delete organization"
						variant="outline-danger"
						size="sm"
						className="py-0 ml-1"
						disabled={props.isRemoving}
						onClick={e => {
							e.stopPropagation();
							props.removeOrganization();
						}}
					>
						{props.isRemoving ? <Spinner /> : <FontAwesomeIcon icon={faTrashCan} />}
					</Button>
				</Flex>
			</td>
		</tr>
	);
};

export default OrganizationRow;
