import supabase from 'util/supabase';

export const keepUnusedDataFor = 5;
export const apiUri = process.env.NODE_ENV === 'development'
	? `${window.location.protocol}//${window.location.hostname}:3001`
	: "https://api.squirrels.ai";

export const prepareHeadersForAuth = (headers, { getState }) => {
	headers.set('Cache-Control', 'no-cache');
	headers.set('Pragma', 'no-cache');
	headers.set('Expires', '0');

	const { access_token } = supabase.auth.session() || {};
	if (access_token) {
		headers.set('authorization', access_token);
	}

	return headers;
};
