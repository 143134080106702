import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import SpinnerCenter from 'components/SpinnerCenter';
import Editor from './Editor';
import {
	// Queries
	useGetArticlesQuery,
	// Mutations
	useUpdateArticleMutation,
} from 'api/client';
import { useParams } from 'util/router';
import './styles.scss';

function EditArticle(props) {
	const { id: idFromParams } = useParams();
	const id = props.id || idFromParams;
	const [editorContent, setEditorContent] = useState();
	const articleLoaded = useRef();
	const editorRef = useRef();

	const {
    data: articles = [],
    isLoading: articlesIsLoading,
    refetch: articlesRefetch,
    // error: articlesError,
  } = useGetArticlesQuery({ id });

  const [article = {}] = articles;

  // Set editor content on load
  useEffect(() => {
  	if (!articlesIsLoading && !articleLoaded.current) {
  		articleLoaded.current = true;
  		setEditorContent(article?.content);
  	}
  }, [
  	articlesIsLoading,
  	article?.content
  ]);

  // Mutations
  const [updateArticle, updateArticleResult] = useUpdateArticleMutation();

	useEffect(() => {
		const { error, isError, isSuccess } = updateArticleResult;
		if (isError) console.error('useUpdateArticleMutation error', error);
		if (isSuccess) setTimeout(articlesRefetch, 750);
		if (isError || isSuccess) setTimeout(updateArticleResult.reset, 750);
	}, [
		updateArticleResult,
		articlesRefetch,
	]);

	const isReadOnly = updateArticleResult.isLoading || updateArticleResult.isSuccess || updateArticleResult.isError;

	return (
		<Card className="EditArticle shadow">
			<Card.Body className="p-0">
				{articlesIsLoading ? (
					<SpinnerCenter />
				) : !article?.content ? (
					<Flex justify="center" align="center" className="p-3">
						<span>Content has not been generated for this article</span>
					</Flex>
				) : (
					<Editor
						ref={editorRef}
						editorContent={editorContent}
						setEditorContent={setEditorContent}
						isReadOnly={isReadOnly}
					/>
				)}
			</Card.Body>
			<Card.Footer>
				<Flex>
					{/*Save*/}
					<Button
						variant={updateArticleResult.isSuccess ? 'success' : updateArticleResult.isError ? 'danger' : 'primary'}
						size="sm"
						disabled={isReadOnly}
						onClick={() => {
							const html = editorContent;
							const text = new DOMParser().parseFromString(html, 'text/html').body.innerText;
							const confirm = !!text || window.confirm('There is no text detected. Are you sure you want to continue? This will erase the article content and you will have to start over by regenerating the article.');

							if (confirm) {
								updateArticle({ id, content: html });
							}
						}}
						className="w-fit"
					>
						{updateArticleResult.isLoading ? (
							<Spinner />
						) : updateArticleResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : updateArticleResult.isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : 'Save Changes'}
					</Button>
					{/*Regenerate*/}
					<Button
						variant={updateArticleResult.isSuccess ? 'success' : updateArticleResult.isError ? 'danger' : 'outline-danger'}
						size="sm"
						disabled={isReadOnly}
						onClick={() => {
							if (
								window.confirm('Regenerating means you will loose the current article content. Are you sure?')
							) {
								updateArticle({ id, content: '' })
							}
						}}
						className="w-fit ml-2"
					>
						{updateArticleResult.isLoading ? (
							<Spinner />
						) : updateArticleResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : updateArticleResult.isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : 'Regenerate Article'}
					</Button>
				</Flex>
			</Card.Footer>
		</Card>
	);
};

export default EditArticle;
