export function _parseFloat(value) {
  return parseFloat(String(value).replace(/,|\$/g, ''));
}

export function commaFormat(value, toFixed, ifIsNaN) {
  if (
    !isNaN(toFixed) &&
    (toFixed === 0 || !!toFixed)
  ) {
    toFixed = [toFixed, 18].sort((a, b) => a-b)[0]; //18 is max
  }

  const format = {
    minimumFractionDigits: toFixed || 0,
    maximumFractionDigits: toFixed || 0,
  };

  const applyFormat = toFixed === 0 || !!toFixed;
  const asComma = Intl.NumberFormat('en', applyFormat && format).format(_parseFloat(value));

  return isNaN(value) || asComma === 'NaN' ? ifIsNaN || asComma : asComma;
}

export function currencyFormat(value, ifIsNaN) {
  const format = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const asCurrency = Intl.NumberFormat('en', format).format(_parseFloat(value));

  return isNaN(value) || asCurrency === '$NaN' ? ifIsNaN || asCurrency : asCurrency;
}

export function fixedAsNumber(value, toFixed, ifIsNaN) {
  if (
    !isNaN(toFixed) &&
    (toFixed === 0 || !!toFixed)
  ) {
    toFixed = [toFixed, 18].sort((a, b) => a-b)[0]; //18 is max
  }

  const format = {
    maximumFractionDigits: typeof toFixed === 'number' ? toFixed : 2,
  };

  const asNumber = _parseFloat(Intl.NumberFormat('en', format).format(_parseFloat(value)));

  return isNaN(value) || asNumber === 'NaN' ? ifIsNaN || asNumber : asNumber;
}

export function compactNumber(value, ifIsNaN) {
  const compact = Intl.NumberFormat('en-US', {
    notation: "compact",
    maximumFractionDigits: 1
  }).format(_parseFloat(value));

  return isNaN(value) || compact === 'NaN' ? ifIsNaN || compact : compact;
}
