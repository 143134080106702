import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Article from 'components/Article';
import Spinner from 'components/Spinner';
import { useGetArticlesQuery } from 'api/client';
import { useParams } from 'util/router';

function ArticleSection(props) {
  const { id } = useParams();

  const {
    data: articles = [],
    isLoading: articlesIsLoading,
  } = useGetArticlesQuery({ id });

  const [article = {}] = articles;

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid className="px-lg-4 px-xl-5">
        <SectionHeader
          title={articlesIsLoading ? (
            <span>Article: {<Spinner className="f-rem-1.25" />}</span>
          ) : (
            <span>{article.title ? article.title : 'Article'}</span>
          )}
          size={3}
          spaced={true}
          className="text-center"
        />
        <Article />
      </Container>
    </Section>
  );
}

export default ArticleSection;
