import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Flex from 'components/Flex';
import FormField from 'components/FormFieldCustom';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';
import { useUpdateArticleMutation } from 'api/client';
import { commaFormat } from 'util/numbers';
import { getDefaultError } from 'util/error';

function GenerateConfig(props) {
	const {
		id: articleId,
		customNotes,
		sectionsCount,
		sectionLength,
		sectionTitles = [],
		type = 'auto',
		isGeneratingContent,
		refetch,
		onSaveConfig,
	} = props;
	const [validated, setValidated] = useState(false);
	const [newSectionsCount, setNewSectionsCount] = useState(sectionsCount);
	const [newSectionLength, setNewSectionLength] = useState(sectionLength);
	const [writeCustomSectionTitles, setWriteCustomSectionTitles] = useState(false);
	const [customSectionTitles, setCustomSectionTitles] = useState([]);

	useEffect(() => {
		if (sectionTitles.length > 0) {
			setWriteCustomSectionTitles(true);
		}

		setCustomSectionTitles(
			[...Array(6).keys()].map(
				(i, idx) => sectionTitles[idx] || ''
			)
		);
	}, [
		sectionTitles
	]);

	// Mutations
	const [updateArticle, updateArticleResult] = useUpdateArticleMutation();

	useEffect(() => {
		const { error, isError, isSuccess } = updateArticleResult;
		if (isError) console.error('useUpdateArticleMutation error', error);
		if (isSuccess) typeof refetch === 'function' && refetch();
		if (isError || isSuccess) {
			setTimeout(() => {
				updateArticleResult.reset();
				isSuccess && onSaveConfig();
			}, 750);
		}
	}, [
		updateArticleResult,
		refetch,
		onSaveConfig,
	]);

	// States
  const isReadOnly = isGeneratingContent || updateArticleResult.isLoading || updateArticleResult.isSuccess;

	// Callbacks
  const handleSubmit = useCallback((e) => {
		e.preventDefault();
		const form = e.currentTarget;

		if (form.checkValidity() === false) {
    	setValidated(true);
      // Exit submission
    } else {
    	updateArticleResult.reset();

    	const params = {
    		id: articleId,
    		sectionsCount: form.sectionsCount.value.trim(),
				sectionLength: form.sectionLength.value.trim(),
				sectionTitles: writeCustomSectionTitles ? [
					...Array(newSectionsCount).keys()
				].map(
					(i, idx) => form[`sectionTitle-${idx}`].value.trim()
				) : [],
    		customNotes: form.customNotes.value.trim(),
    		type: form.type.value.trim(),
    	};

    	updateArticle(params);
    }
	}, [
		articleId,
		newSectionsCount,
		writeCustomSectionTitles,
		updateArticle,
		updateArticleResult,
	]);

	const handleOnChange = useCallback(() => {
		setValidated(false);
		updateArticleResult.reset();
	}, [updateArticleResult]);

	return (
		<Card className="GenerateConfig shadow">
			<CardHeader
				wordCount={newSectionsCount * newSectionLength}
			/>
			<Card.Body>
				<Form
					noValidate
					validated={validated}
					onSubmit={handleSubmit}
				>
					{/*Sections count in article*/}
					<div style={{maxWidth: '280px'}}>
						<FormField
							id="sectionsCount"
							label={
								<span>Sections in article: <span className="fw6">{newSectionsCount}</span></span>
							}
							type="range"
							value={newSectionsCount}
							step="1"
							min="1"
							max="6"
							onChange={e => {
								setNewSectionsCount(Number(e.target.value));
								handleOnChange();
							}}
							readOnly={isReadOnly}
						/>
					</div>
					{/*Section length*/}
					<div style={{maxWidth: '280px'}}>
						<FormField
							id="sectionLength"
							label={
								<span>Approx # of words per section: <span className="fw6">{newSectionLength}</span></span>
							}
							type="range"
							value={newSectionLength}
							step="50"
							min="200"
							max="600"
							onChange={e => {
								setNewSectionLength(Number(e.target.value));
								handleOnChange();
							}}
							readOnly={isReadOnly}
						/>
					</div>
					{/*Article Type*/}
					<FormField
						id="type"
						label="Article type"
						type="text"
						placeholder="Article type"
						value={type}
						required={true}
						readOnly={true}
						subtext="This cannot be changed right now"
					/>
					{/*Custom Notes*/}
					<FormField
						id="customNotes"
						label="Custom notes for content creation (optional)"
						type="textarea"
						placeholder="Custom notes"
						defaultValue={customNotes || ''}
						onChange={handleOnChange}
						readOnly={isReadOnly}
					/>
					{/*Custom section titles*/}
					<Toggle
						ops={['generate section titles', 'write my own section titles']}
						active={!writeCustomSectionTitles ? 'generate section titles' : 'write my own section titles'}
						setActive={op => setWriteCustomSectionTitles(op === 'write my own section titles')}
						className="w-fit mb-3 scroll-x"
						disabled={isReadOnly}
					/>

					{writeCustomSectionTitles && customSectionTitles.map(
						(title, idx) => (idx + 1 <= newSectionsCount) && (
							<FormField
								key={idx}
								id={`sectionTitle-${idx}`}
								label={`Custom section title ${idx+1}`}
								type="text"
								placeholder={`Section title ${idx+1}`}
								defaultValue={title || ''}
								readOnly={isReadOnly}
								required={true}
								onChange={e => {
									handleOnChange();
									setCustomSectionTitles(prev => prev.map(
										(t, _idx) => _idx === idx ? e.target.value : t
									));
								}}
							/>
						)
					)}

					{/*Submit*/}
					<Button
						variant={updateArticleResult.isSuccess ? 'success' : updateArticleResult.isError ? 'danger' : 'primary'}
						size="sm"
						type="submit"
						disabled={isGeneratingContent || updateArticleResult.isLoading || updateArticleResult.isSuccess}
					>
						{isGeneratingContent || updateArticleResult.isLoading ? (
							<Flex
								align="center"
							>
								<Spinner />{isGeneratingContent && (<span className="ml-2">Article is generating</span>)}
							</Flex>
						) : updateArticleResult.isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : updateArticleResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : 'Save'}
					</Button>
				</Form>

				{updateArticleResult.isError && (
					<Alert variant="danger" className="mt-3 mb-0">
						<Flex direction="column" align="center" className="w-100">
							<span>{getDefaultError(updateArticleResult?.error)}</span>
						</Flex>
					</Alert>
				)}
			</Card.Body>
		</Card>
	);
};

export default GenerateConfig;


const CardHeader = (props) => (
	<Card.Header>
		<Flex
			justify="between"
			align="center"
		>
			<span>Configuration</span>
			<span className="f-rem-0.9 fw5 text-gray-800">~{commaFormat(props.wordCount)} words</span>
		</Flex>
	</Card.Header>
);
