import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ArticlesTable from 'components/ArticlesTable';
import BackBtn from 'components/BackBtn';
import Flex from 'components/Flex';
import SpinnerCenter from 'components/SpinnerCenter';
import Toggle from 'components/Toggle';
import { useGetOrganizationsQuery } from 'api/client';
import { useParams, useHistory } from 'util/router';
import { getDefaultError } from 'util/error';
import InfoCard from './InfoCard';
import KeywordsOrPersonasTable from './KeywordsOrPersonasTable';
import './styles.scss';

const TABS = ['articles', 'keywords', 'target personas'];

function Organization(props) {
	const history = useHistory();
	const { id } = useParams();
	const [activeTab, setActiveTab] = useState(TABS[0]);

	const {
  	data: organizations = [],
  	isLoading: organizationsIsLoading,
  	refetch: organizationsRefetch,
  	error: organizationsError,
  } = useGetOrganizationsQuery({ id });

  const [organization = {}] = organizations;

	return organizationsIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<>
			{organizationsError && (
				<Alert variant="danger" className="mb-4">
					{getDefaultError(organizationsError)}
				</Alert>
			)}
			<div className="Organization">
				{/*Back btn*/}
				<Row>
		  		<Col xs={12}>
		  			<Flex
		  				align="center"
		  				style={{marginTop: '-1rem', marginBottom: '.5rem'}}
		  			>
		  				<BackBtn
		  					className="text-gray-700 text-gray-700-hvr-dark"
		  					onClick={() => history.goBack()}
		  				/>
		  			</Flex>
		  		</Col>
		  	</Row>
		  	<Row>
					<Col xs={12} lg={4} xl={3}>
						<InfoCard
							{...(organization || {})}
							refetch={organizationsRefetch}
						/>
					</Col>
					<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
						<Toggle
							ops={TABS}
							active={activeTab}
							setActive={op => setActiveTab(op)}
							className="w-fit mb-2"
						/>

						{activeTab === 'articles' ? (
							<ArticlesTable
								organizationId={organization.id}
							/>
						) : activeTab === 'keywords' ? (
							<KeywordsOrPersonasTable
								label="Keywords"
								items={organization.keywords}
								isLoading={organizationsIsLoading}
							/>
						) : activeTab === 'target personas' ? (
							<KeywordsOrPersonasTable
								label="Target Personas"
								items={organization.targetPersonas}
								isLoading={organizationsIsLoading}
							/>
						) : null}
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Organization;
