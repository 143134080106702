import { configureStore } from '@reduxjs/toolkit';
import {
	articlesApiSlice,
	organizationsApiSlice,
	usersApiSlice,
} from 'api/client';

export default configureStore({
	reducer: {
		[articlesApiSlice.reducerPath]: articlesApiSlice.reducer,
		[organizationsApiSlice.reducerPath]: organizationsApiSlice.reducer,
		[usersApiSlice.reducerPath]: usersApiSlice.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			articlesApiSlice.middleware,
		).concat(
			organizationsApiSlice.middleware
		).concat(
			usersApiSlice.middleware
		)
});
