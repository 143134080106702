import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import SearchInput from 'components/SearchInput';
import Spinner from 'components/Spinner';
import SpinnerCenter from 'components/SpinnerCenter';
import {
	// Queries
	useGetArticlesQuery,
	// Mutations
	useCreateBlankArticleMutation,
} from 'api/client';
import { useHistory, useParams } from 'util/router';
import ArticleRow from './ArticleRow';
import Filter from './Filter';

function ArticlesTable(props) {
	const history = useHistory();
	const { id } = useParams();
	const { organizationId } = props;
	const [search, setSearch] = useState('');
	const [statusFilter, setStatusFilter] = useState([]);

	const {
  	data: _articles = [],
  	isLoading: articlesIsLoading,
  	refetch: articlesRefetch,
  } = useGetArticlesQuery({
  	...organizationId ? {
  		organizationId
  	} : id ? {
  		id
  	} : {},
  	...search ? { search } : {},
  });

  const articles = useMemo(() => {
  	return statusFilter.length > 0 ? _articles.filter(
  		a => statusFilter.includes(a.status)
  	) : _articles;
  }, [_articles, statusFilter]);

  // Callbacks
  const handleNavigateToArticle = useCallback(
  	id => history.push(`/articles/${id}`),
  	[history]
  );

  const handleNavigateToOrganization = useCallback(
  	id => history.push(`/organizations/${id}`),
  	[history]
  );

  // Mutations
  const [createArticle, createArticleResult] = useCreateBlankArticleMutation();

  useEffect(() => {
		if (createArticleResult.isError) {
			console.error('useCreateBlankArticleMutation error', createArticleResult.error);
		}
		if (createArticleResult.isSuccess) {
			articlesRefetch();

			if (createArticleResult?.data?.id) {
				handleNavigateToArticle(createArticleResult?.data?.id);
			} else {
				createArticleResult.reset();
			}
		}
	}, [
		createArticleResult,
		articlesRefetch,
		handleNavigateToArticle
	]);

  return articlesIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
  	<>
			<Card className="ArticlesTable shadow">
				<Card.Header>
					<Flex
						justify="between"
						align="center"
					>
		  			<SearchInput
		  				placeholder="Search by article name"
		  				setSearch={setSearch}
		  				debounceTime={250}
		  			/>
		  			{organizationId && (
			  			<Button
			  				className="ml-2"
			  				disabled={createArticleResult.isLoading}
			  				onClick={() => {
			  					if (window.confirm('Create a new article?')) {
			  						createArticle({ organizationId })
			  					}
			  				}}
			  			>
			  				{createArticleResult.isLoading ? (
			  					<Spinner />
			  				) : (
			  					<FontAwesomeIcon icon={faPlus} />
			  				)}
			  			</Button>
		  			)}
		  			<Filter
		  				statusFilter={statusFilter}
		  				setStatusFilter={setStatusFilter}
		  			/>
					</Flex>
	  		</Card.Header>
	  		<Card.Body className="p-0 scroll-x">
	  			{articles?.length > 0 ? (
	  				<Table size="sm" className="responsive table-stripe">
							<thead>
								<tr>
									<th>Title</th>
									<th>Organization</th>
									<th>Type</th>
									<th>Length</th>
									<th>Status</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{articles?.map?.(article => (
									<ArticleRow
										key={article.id}
										{...article}
										navigateToArticle={handleNavigateToArticle}
										navigateToOrganization={handleNavigateToOrganization}
										articlesRefetch={articlesRefetch}
									/>
								))}
							</tbody>
						</Table>
	  			) : (
	  				<Flex justify="center" className="text-danger py-2">
							<span>No articles found{search && ' matching your search'}</span>
						</Flex>
	  			)}
	  		</Card.Body>
			</Card>
		</>
	);
};

export default ArticlesTable;
