import React, { useState } from "react";
import classnames from 'classnames';
import dayjs from 'dayjs';
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Flex from 'components/Flex';
import BackBtn from 'components/BackBtn';
import Spinner from "components/Spinner";
import SearchInput from "components/SearchInput";
import Toggle from "components/Toggle";
import UsersTable from "components/UsersTable";
import { useParams, useHistory } from 'util/router';
import { getDefaultError } from 'util/error';
import { useGetUsersQuery } from "api/client";
import useIsMobile from 'hooks/useIsMobile';


const TABS = [
	'info',
];

function User(props) {
	const history = useHistory();
	const { userId } = useParams();
	const [activeTab, setActiveTab] = useState(TABS[0]);

	const {
		data: users = [],
		isLoading: usersIsLoading,
		isFetching: usersIsFetching,
		refetch: usersRefetch,
		isError: usersIsError,
		error: usersError,
	} = useGetUsersQuery({ userId });

	const [user = {}] = users;

	return (
		<>
			{usersIsError && <UserErrorAlert error={usersError} refetch={usersRefetch} />}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
				<Container fluid="lg">
					<SectionHeader
					  title={!usersIsLoading ? (user?.email || 'User not found') : <Spinner className="text-muted"/>}
					  subtitle={!usersIsLoading && `${user?.firstName} ${user?.lastName}`.trim()}
					  size={3}
					  spaced={true}
					  className="text-center"
					/>
					<Row>
						{/*Back btn*/}
						<Col xs={12}>
							<Flex
								align="center"
								style={{marginTop: '-1rem', marginBottom: '.5rem'}}
							>
								<BackBtn
									className="text-gray-700 text-gray-700-hvr-dark"
									onClick={() => history.goBack()}
								/>
							</Flex>
						</Col>
						{/*Summary*/}
						<Col md={12} lg={4} xl={3}>
							<UserSummaryCard
								user={user}
								isFetching={usersIsFetching}
							/>
						</Col>
						{/*Main*/}
						<Col md={12} lg={8} xl={9}>
							<TabsToggle
								activeTab={activeTab}
								setActiveTab={setActiveTab}
							/>
			  			<div className={classnames({
		  					'd-none': activeTab !== 'info'
			  			})}>

			  				<UsersTableCard
			  					user={user}
			  					isLoading={usersIsLoading}
			  					isFetching={usersIsFetching}
			  				/>
			  			</div>
						</Col>
					</Row>
				</Container>
			</Section>
		</>
	);
};

const UserErrorAlert = ({error, refetch}) => (
	<Alert variant="danger">
		<Flex direction="column" align="center" className="w-100">
			<span>{getDefaultError(error)}</span>
			<span
				className="text-info-dark fw5 pointer"
				onClick={refetch}
			>
				Retry?
			</span>
		</Flex>
	</Alert>
);

const UserSummaryCard = ({user, isFetching}) => {
	return (
		<Card className="shadow mb-3">
			{isFetching ? (
				<Card.Body className="p-3">
					<Spinner className="text-muted w-100"/>
				</Card.Body>
			) : (
				<>
					<Card.Header className="text-center">
						<span>{user?.id || '-'}</span>
					</Card.Header>
					<Card.Body className="py-2">
						<Flex direction="column">
							<Flex justify="between" className="py-1">
								<span className="text-gray-700">First Name</span>
								<span className="text-right pl-2.5 text-truncate">{user?.firstName || '-'}</span>
							</Flex>
							<Flex justify="between" className="py-1">
								<span className="text-gray-700">Last Name</span>
								<span className="text-right pl-2.5 text-truncate">{user?.lastName || '-'}</span>
							</Flex>
							<Flex justify="between" className="py-1">
								<span className="text-gray-700">Email</span>
								<span className="text-right pl-2.5 text-truncate">{user?.email || '-'}</span>
							</Flex>
							<Flex justify="between" className="py-1">
								<span className="text-gray-700">Confirmed</span>
								<span className="text-right pl-2.5 text-truncate">{user?.emailConfirmed ? 'Yes' : 'No'}</span>
							</Flex>
							<Flex justify="between" className="py-1">
								<span className="text-gray-700">Role</span>
								<span className="text-right pl-2.5 text-truncate">{user?.role || '-'}</span>
							</Flex>
							<Flex justify="between" className="py-1">
								<span className="text-gray-700">Last Seen</span>
								<span className="text-right pl-2.5 text-truncate">{user?.lastAccessed ? dayjs(user?.lastAccessed).fromNow() : 'Never'}</span>
							</Flex>
						</Flex>
					</Card.Body>
				</>
			)}
		</Card>
	);
};

const TabsToggle = ({activeTab, setActiveTab}) => {
	const isMobile = useIsMobile();

	return (
		<Toggle
			ops={TABS}
			active={activeTab}
			setActive={setActiveTab}
			className={classnames('mb-3 w-fit', {
				'flex-wrap justify-content-around': isMobile
			})}
		/>
	);
};

const UsersTableCard = ({user, isLoading, isFetching}) => {
	const [search, setSearch] = useState('');

	return (
		<Card className="shadow mb-3">
			<Card.Header>
				<Flex justify="between" align="center">
					<span className="mr-2">User</span>
					<div className="flex-grow-1" style={{maxWidth: '80%'}}>
						<SearchInput setSearch={setSearch} />
					</div>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				<div>
					{isFetching ? (
						<Spinner className="p-3 text-muted w-100"/>
					) : (
						<UsersTable
							users={[user]}
							isLoading={isLoading}
							isFetching={isFetching}
							hasLocalSort={true}
							search={search}
							searchKeys={['email']}
						/>
					)}
				</div>
			</Card.Body>
		</Card>
	);
}

export default User;
