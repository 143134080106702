export function getDefaultError(err, defaultErr = 'An unknown error occured') {
	const getValidationErrorStr = () => {
		const { query, body } = err?.data?.details || {};
		const errArray = query || body;
		return errArray?.map?.(o => o.message).join('; ');
	};

	return !err ? defaultErr
		: err?.data?.name === "ValidationError" ? getValidationErrorStr() || defaultErr
			: err?.data?.message || err?.message || err?.error || defaultErr;
}
