import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import App from "./pages/_app";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";

ReactDom.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
