import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Flex from 'components/Flex';

function FormField(props) {
	const {
		id,
		wrapperClassName = "",
		label,
		labelClassName = '',
		type,
		placeholder,
		defaultValue,
		required,
		onChange,
		readOnly,
		subtext,
		invalidFeedback,
		remove,
		...rest
	} = props;

	const formControlProps = {
		...type ? type === 'textarea' ? { as: 'textarea', rows: 3 } : { type } : {},
		...placeholder ? { placeholder } : {},
		...defaultValue ? { defaultValue } : {},
		...required ? { required: true } : {},
		...readOnly ? { readOnly: true } : {},
		...onChange ? { onChange } : {},
	};

	return (
		<Form.Group
			controlId={id || label?.toLowerCase()}
			className={(wrapperClassName || '').trim()}
		>
			<Flex
				justify="between"
				align="center"
				className={classnames({
					'mb-1': remove,
				})}
			>
				<Form.Label
					className={`mb-1 ${labelClassName}`.trim()}
				>
					{label}
				</Form.Label>
				{remove && (
					<Button
						variant="outline-danger"
						size="sm"
						className="py-0 ml-1"
						onClick={remove}
					>
						<FontAwesomeIcon icon={faTrashCan} />
					</Button>
				)}
			</Flex>
			<Form.Control {...formControlProps} {...rest}/>
			{subtext && (
				<Form.Text className="text-muted">{subtext}</Form.Text>
			)}
			{invalidFeedback && (
				<Form.Control.Feedback type="invalid">{invalidFeedback}</Form.Control.Feedback>
			)}
		</Form.Group>
	);
};

export default FormField;
