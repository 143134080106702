import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import EditOrganizationPopup from 'components/EditOrganizationPopup';
import Flex from 'components/Flex';
import SearchInput from 'components/SearchInput';
import SpinnerCenter from 'components/SpinnerCenter';
import {
	// Queries
	useGetOrganizationsQuery,
	// Mutations
	useRemoveOrganizationMutation,
} from 'api/client';
import { useHistory } from 'util/router';
import OrganizationRow from './OrganizationRow';

function OrganizationsTable(props) {
	const history = useHistory();
	const [search, setSearch] = useState('');
	const [showEditOrgPopup, setShowEditOrgPopup] = useState(false);

	const {
  	data: organizations = [],
  	isLoading: organizationsIsLoading,
  	refetch: organizationsRefetch,
  	error,
  } = useGetOrganizationsQuery({ search });

  const handleNavigateToOrganization = useCallback(
  	id => history.push(`/organizations/${id}`),
  	[history]
  );

  // Mutations
  const [removeOrg, removeOrgResult] = useRemoveOrganizationMutation();

  useEffect(() => {
		if (removeOrgResult.isError) {
			console.error('useRemoveOrganizationMutation error', removeOrgResult.error);
		}
		if (removeOrgResult.isSuccess) {
			organizationsRefetch();
		}
	}, [
		removeOrgResult,
		organizationsRefetch
	]);

  // Callbacks
  const handleDeleteOrganization = useCallback(
  	id => {
  		if (
  			!removeOrgResult.isLoading &&
  			window.confirm('Are you sure? This cannot be undone.')
  		) {
  			removeOrg({ id });
  		}
  	},
  	[
  		removeOrg,
  		removeOrgResult
  	]
  );

	return organizationsIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<>
			<Card className="OrganizationsTable shadow">
				<Card.Header>
					<Flex
						justify="between"
						align="center"
					>
		  			<SearchInput
		  				placeholder="Search by org name"
		  				setSearch={setSearch}
		  				debounceTime={250}
		  			/>
		  			<Button
		  				className="ml-2"
		  				onClick={() => setShowEditOrgPopup(true)}
		  			>
		  				<FontAwesomeIcon icon={faPlus} />
		  			</Button>
					</Flex>
	  		</Card.Header>
	  		<Card.Body className="p-0 scroll-x">
	  			{organizations?.length > 0 ? (
	  				<Table size="sm" className="responsive table-stripe">
							<thead>
								<tr>
									<th>Name</th>
									<th>Website</th>
									<th>Info</th>
									<th>Guidelines</th>
									<th>Value prop</th>
									<th>Articles</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{organizations?.map?.(organization => (
									<OrganizationRow
										key={organization.id}
										{...organization}
										navigateToOrganization={handleNavigateToOrganization}
										editOrganization={() => setShowEditOrgPopup(organization.id)}
										removeOrganization={() => handleDeleteOrganization(organization.id)}
										isRemoving={removeOrgResult?.originalArgs?.id === organization.id && removeOrgResult.isLoading}
									/>
								))}
							</tbody>
						</Table>
	  			) : (
	  				<Flex justify="center" className="text-danger py-2">
	  					{error?.data?.message ? (
	  						<span>{error?.data?.message || 'An unknown error occured'}</span>
	  					) : (
								<span>No organizations found{search && ' matching your search'}</span>
	  					)}
						</Flex>
	  			)}
	  		</Card.Body>
			</Card>
			{showEditOrgPopup && (
				<EditOrganizationPopup
					id={typeof showEditOrgPopup === 'boolean' ? null : showEditOrgPopup}
					afterClose={() => {
						setShowEditOrgPopup(false);
						organizationsRefetch();
					}}
				/>
			)}
		</>
	);
};

export default OrganizationsTable;
