import React from "react";
import Meta from "components/Meta";
import PublicOrganizationSection from "components/PublicOrganizationSection";

function PublicOrganizationPage(props) {
  return (
    <>
      <Meta title="Public Organization" />
      <PublicOrganizationSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="View Organization"
      	subtitle=""
      />
    </>
  );
}

export default PublicOrganizationPage;
