import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import { useGenerateArticleMutation } from 'api/client';
import { getDefaultError } from 'util/error';
import { commaFormat } from 'util/numbers';

function GenerateArticle(props) {
	const { refetch } = props;

	// Mutations
	const [generateArticle, generateArticleResult] = useGenerateArticleMutation();

	useEffect(() => {
		const { error, isError, isSuccess, isLoading } = generateArticleResult;
		if (isLoading) {
			setTimeout(() => typeof refetch === 'function' && refetch(), 250);
		}
		if (isError) console.error('useGenerateArticleMutation error', error);
		if (isSuccess) {
			typeof refetch === 'function' && refetch();
			setTimeout(() => generateArticleResult.reset(), 750);
		}
	}, [
		generateArticleResult,
		refetch
	]);

	return (
		<Card className="shadow">
			<Card.Header>
				Create article
			</Card.Header>
			<CardBody
				{...props}
				isGenerating={props.isGeneratingContent || generateArticleResult.isLoading}
			/>
			<CardFooter
				{...props}
				generateArticle={generateArticle}
				generateArticleResult={generateArticleResult}
			/>
		</Card>
	);
};

export default GenerateArticle;


const CardBody = (props) => (
	<Card.Body
		className={classnames({
			'bg-light': props.isGenerating,
		})}
	>
		<Row>
			<Col className="d-flex flex-column">
				<span className="f-rem-0.85 fw5 text-gray-600">Title</span>
				<span>{props.title}</span>
			</Col>
		</Row>
		<hr className="my-2.5" />
		<Row className="mt-1.5">
			<Col xs={6} sm={4} md={3} xl={2} className="d-flex flex-column">
				<span className="f-rem-0.85 fw5 text-gray-600">Sections</span>
				<span>{commaFormat(props.sectionsCount)}</span>
			</Col>
			<Col xs={6} sm={4} md={3} xl={2} className="d-flex flex-column">
				<span className="f-rem-0.85 fw5 text-gray-600">Words per section</span>
				<span>{commaFormat(props.sectionLength)}</span>
			</Col>
		</Row>
		<Row className="mt-1.5">
			<Col xs={6} sm={4} md={3} xl={2} className="d-flex flex-column">
				<span className="f-rem-0.85 fw5 text-gray-600">Approx word count</span>
				<span>{commaFormat(props.sectionsCount * props.sectionLength)}</span>
			</Col>
			<Col xs={6} sm={4} md={3} xl={2} className="d-flex flex-column">
				<span className="f-rem-0.85 fw5 text-gray-600">Article type</span>
				<span className="text-capitalize">{props.type}</span>
			</Col>
		</Row>
		{props.customNotes && (
			<Row className="mt-1.5">
				<Col className="d-flex flex-column">
					<span className="f-rem-0.85 fw5 text-gray-600">Custom notes</span>
					<span>{props.customNotes}</span>
				</Col>
			</Row>
		)}
		<hr className="my-2.5" />
		<Row className="mt-1.5">
			{props.sectionTitles?.length > 0 ? (
				<>
					<Col xs={12}>
						<span className="f-rem-0.95 fw5 text-gray-700">Custom section titles</span>
					</Col>
					{props.sectionTitles.map((title, idx) => (
						<Col
							key={idx}
							xs={12}
							className="d-flex flex-column mt-1.5"
						>
							<span className="f-rem-0.85 fw5 text-gray-600">Custom section title {idx+1}</span>
							<span>{title}</span>
						</Col>
					))}
				</>
			) : (
				<Col xs={12} className="d-flex flex-column">
					<span className="f-rem-0.95 fw5 text-gray-700">Section titles will be generated</span>
				</Col>
			)}
		</Row>
	</Card.Body>
);

const CardFooter = (props) => {
	const { isGeneratingContent, generateArticle, generateArticleResult } = props;
	const { isError, isSuccess, isLoading, error, reset } = generateArticleResult;
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		let interval;

		if (isGeneratingContent || isLoading) {
			interval = setInterval(
				() => setProgress(
					prev => prev >= 100 ? 100 : prev + 1
				), 2700 //takes 4.5min to get to 100
			);
		} else {
			setProgress(0);
		}

		return () => clearInterval(interval);
	}, [isGeneratingContent, isLoading]);

	return (
		<Card.Footer>
			<Flex direction="column">
				<Flex>
					<Button
						variant={isSuccess ? 'success' : isError ? 'danger' : 'primary'}
						size="sm"
						disabled={isGeneratingContent || isLoading || isSuccess || isError}
						onClick={() => {
							generateArticle({
								id: props.id,
								title: props.title,
								brandGuidelines: props?.organization?.brandGuidelines || '',
								customNotes: props.customNotes || '',
								sectionsCount: props.sectionsCount,
								sectionLength: props.sectionLength,
								...props.sectionTitles.length > 0 ? {
									sectionTitles: props.sectionTitles
								} : {},
								update: true,
							});
						}}
						className="w-fit"
					>
						{isGeneratingContent || isLoading ? (
							<><Spinner className="mr-2" /><span>Generating</span></>
						) : isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : 'Generate Article'}
					</Button>
					{isError && (
						<Button
							variant="link"
							size="sm"
							disabled={isGeneratingContent || isLoading}
							onClick={reset}
							className="ml-2 text-warning"
						>
							Reset
						</Button>
					)}
				</Flex>
				{isGeneratingContent || isLoading ? (
					<ProgressBar
						variant="success"
						animated
						now={progress}
						className="mt-1.5"
						style={{height: '.3rem'}}
					/>
				) : isError ? (
					<span className="f-rem-0.9 fw5 text-danger mt-2">{getDefaultError(error)}</span>
				) : null}
			</Flex>
		</Card.Footer>
	);
};
