import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import EditOrganizationPopup from 'components/EditOrganizationPopup';
import { useAuth } from 'util/auth';
import { commaFormat } from 'util/numbers';
import { generatePublicOrgLink } from 'util/organization';
import { STATUS_MAP } from 'components/Article/constants';

function InfoCard(props) {
	const auth = useAuth();
	const [showEditOrgPopup, setShowEditOrgPopup] = useState(false);

	return (
		<>
			<Card className="OrganizationInfoCard shadow">
				<CardHeader
					{...props}
					isAdmin={!!auth?.user}
					editOrg={() => setShowEditOrgPopup(true)}
				/>
				<CardBody {...props} />
				<CardFooter
					{...props}
					isAdmin={!!auth?.user}
				/>
			</Card>
			{showEditOrgPopup && (
				<EditOrganizationPopup
					id={props.id}
					afterClose={() => {
						setShowEditOrgPopup(false);
						props.refetch();
					}}
				/>
			)}
		</>
	);
};

export default InfoCard;


const CardHeader = (props) => (
	<Card.Header>
		<Flex
			justify="between"
			align="center"
		>
			<span className="fw5">{props.name}</span>
			{props.isAdmin && (
				<Button
					variant="outline-primary"
					size="sm"
					className="py-0"
					onClick={props.editOrg}
				>
					<FontAwesomeIcon icon={faPenToSquare} />
				</Button>
			)}
		</Flex>
	</Card.Header>
);

const CardBody = (props) => {
	const { articles = [] } = props;

	const [
		articlesCount,
		articlesPendingEditorReviewCount,
		articlesPendingClientReviewCount,
		articlesPublishedCount,
	] = useMemo(() => {
		const articlesCount = articles.length;
		const articlesPendingEditorReviewCount = articles.filter(
			o => o.status === STATUS_MAP.EDITOR_REVIEW
		);
		const articlesPendingClientReviewCount = articles.filter(
			o => o.status === STATUS_MAP.PENDING_CLIENT_APPROVAL
		);
		const articlesPublishedCount =  articles.filter(
			o => o.status === STATUS_MAP.PUBLISHED
		);

		return [
			articlesCount,
			articlesPendingEditorReviewCount,
			articlesPendingClientReviewCount,
			articlesPublishedCount,
		];
	}, [articles]);

	return (
		<Card.Body>
			{/*Website*/}
			<Flex className="line-item-wrapper">
				<Flex className="item-wrapper text-truncate" title={props.website}>
					<span className="label">Website</span>
					<span className="value text-truncate">
						<a
							href={props.website ? /\/\//.test(props.website) ? props.website : `//${props.website}` : ''}
							target="_blank"
							rel="noreferrer"
							onClick={e => e.stopPropagation()}
						>
							{props.website}
						</a>
					</span>
				</Flex>
			</Flex>
			{/*Articles*/}
			<Flex className="line-item-wrapper">
				<Flex className="item-wrapper text-truncate" title={articlesCount}>
					<span className="label">Articles</span>
					<span className="value text-truncate">{commaFormat(articlesCount, 0, '0')}</span>
				</Flex>
				<Flex className="item-wrapper text-truncate" title={articlesPendingEditorReviewCount}>
					<span className="label">Pending Editor Review</span>
					<span className="value text-truncate">{commaFormat(articlesPendingEditorReviewCount, 0, '0')}</span>
				</Flex>
			</Flex>
			<Flex className="line-item-wrapper">
				<Flex className="item-wrapper text-truncate" title={articlesPendingClientReviewCount}>
					<span className="label">Pending Client Review</span>
					<span className="value text-truncate">{commaFormat(articlesPendingClientReviewCount, 0, '0')}</span>
				</Flex>
				<Flex className="item-wrapper text-truncate" title={articlesPublishedCount}>
					<span className="label">Published</span>
					<span className="value text-truncate">{commaFormat(articlesPublishedCount, 0, '0')}</span>
				</Flex>
			</Flex>
		</Card.Body>
	);
};

const CardFooter = (props) => (
	<Card.Footer>
		<Flex direction="column">
			<Flex justify="between" align="center">
				<span className="f-rem-0.65 fw5 text-gray-600">Org ID</span>
				{props.isAdmin && (
					<Button
						variant="link"
						size="sm"
						className="p-0 f-rem-0.65 fw5"
						href={generatePublicOrgLink(props.id)}
						target="_blank"
					>
						Public page <FontAwesomeIcon icon={faUpRightFromSquare} />
					</Button>
				)}
			</Flex>
			<span className="f-rem-0.75 text-gray-700">{props.id}</span>
		</Flex>
	</Card.Footer>
);
