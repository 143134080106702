import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const checkIsMobile = () => window.innerWidth <= 991;

function useIsMobile() {
	const [isMobile, setIsMobile] = useState(checkIsMobile());

	useEffect(() => {
		const debounceHandleOnResize = debounce(() => setIsMobile(checkIsMobile()), 200);
		window.addEventListener('resize', debounceHandleOnResize);
		return () => window.removeEventListener('resize', debounceHandleOnResize);
	}, []);

	return isMobile;
}

export default useIsMobile;
