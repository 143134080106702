import React from "react";
import Meta from "components/Meta";
import OrganizationSection from "components/OrganizationSection";
import { requireAuth } from "util/auth";

function OrganizationPage(props) {
  return (
    <>
      <Meta title="Organization" />
      <OrganizationSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Organization"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(OrganizationPage);
