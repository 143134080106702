import React, { useState, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BackBtn from 'components/BackBtn';
import EditArticle from 'components/EditArticle';
import Flex from 'components/Flex';
import SpinnerCenter from 'components/SpinnerCenter';
import Toggle from 'components/Toggle';
import { useGetArticlesQuery } from 'api/client';
import { useParams, useHistory } from 'util/router';
import { getDefaultError } from 'util/error';
import { TABS } from './constants';
import InfoCard from './InfoCard';
import GenerateArticle from './GenerateArticle';
import GenerateConfig from './GenerateConfig';
import TitleGenerator from './TitleGenerator';
import './styles.scss';

function Article(props) {
	const history = useHistory();
	const { id } = useParams();
	const [activeTab, setActiveTab] = useState(TABS.TITLE);
	const articleLoadedRef = useRef(false);

	const {
    data: articles = [],
    isLoading: articlesIsLoading,
    refetch: articlesRefetch,
    error: articlesError,
  } = useGetArticlesQuery({ id });

  const [article = {}] = articles;

  // Determine tab position based on what article already has only on load
  useEffect(() => {
  	if (!articlesIsLoading && !articleLoadedRef.current) {
  		articleLoadedRef.current = true;

  		if (!article?.title) setActiveTab(TABS.TITLE);
  		else if (article?.isGeneratingContent || article?.content) setActiveTab(TABS.CONTENT);
  		else if (!article?.content) setActiveTab(TABS.CONFIG);
  	}
  }, [
  	articlesIsLoading,
  	article?.title,
  	article?.content,
  	article?.isGeneratingContent,
  ]);

  // If isGeneratingContent, set refetch interval to every 5s
  useEffect(() => {
  	let interval;

  	if (article?.isGeneratingContent) {
  		interval = setInterval(articlesRefetch, 5 * 1000);
  	}

  	return () => clearInterval(interval);
  }, [
  	article?.isGeneratingContent,
  	articlesRefetch
  ]);

  // Callbacks
  const handleNavigateToOrganization = useCallback(
  	id => history.push(`/organizations/${id}`),
  	[history]
  );

	return articlesIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<>
			{articlesError && (
				<Alert variant="danger" className="mb-4">
					{getDefaultError(articlesError)}
				</Alert>
			)}
			<div className="Article">
				{/*Back btn*/}
				<Row>
		  		<Col xs={12}>
		  			<Flex
		  				align="center"
		  				style={{marginTop: '-1rem', marginBottom: '.5rem'}}
		  			>
		  				<BackBtn
		  					className="text-gray-700 text-gray-700-hvr-dark"
		  					onClick={() => history.goBack()}
		  				/>
		  			</Flex>
		  		</Col>
		  	</Row>
		  	<Row>
		  		{/*Left*/}
		  		<Col xs={12} lg={4} xl={3}>
		  			<InfoCard
		  				{...article}
		  				refetch={articlesRefetch}
		  				navigateToOrganization={handleNavigateToOrganization}
		  			/>
		  		</Col>
		  		{/*Right*/}
		  		<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
		  			<Toggle
		  				ops={Object.values(TABS)}
							active={activeTab}
							setActive={setActiveTab}
							className="w-fit mb-2"
							disabled={article?.isGeneratingContent}
		  			/>

		  			{/*Title generator*/}
		  			<div
		  				className={classnames('config-cards-wrapper', {
		  					'd-none': activeTab !== 'title'
		  				})}
		  			>
		  				<TitleGenerator
		  					id={article?.id}
		  					title={article?.title}
		  					isGeneratingContent={article?.isGeneratingContent}
		  					orgName={article?.organization?.name}
		  					brandInfo={article?.organization?.brandInfo}
		  					brandGuidelines={article?.organization?.brandGuidelines}
		  					keywords={article?.organization?.keywords}
		  					targetPersonas={article?.organization?.targetPersonas}
		  					refetch={articlesRefetch}
		  					onSetTitle={() => setActiveTab(TABS.CONFIG)}
		  				/>
		  			</div>
		  			{/*Config for article*/}
		  			<div
		  				className={classnames('config-cards-wrapper', {
		  					'd-none': activeTab !== 'config'
		  				})}
		  			>
		  				<GenerateConfig
		  					{...article}
		  					refetch={articlesRefetch}
		  					onSaveConfig={() => setActiveTab(TABS.CONTENT)}
		  				/>
		  			</div>
		  			{/*Article generator*/}
		  			<div
		  				className={classnames('config-cards-wrapper', {
		  					'd-none': activeTab !== 'content'
		  				})}
		  			>
		  				{article?.content ? (
		  					<EditArticle id={article?.id} />
		  				) : (
			  				<GenerateArticle
			  					{...article}
			  					refetch={articlesRefetch}
			  				/>
		  				)}
		  			</div>
		  		</Col>
		  	</Row>
		  </div>
		</>
	);
};

export default Article;
