import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import UsersTable from "components/UsersTable";

export default function UsersSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title="Users"
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        />
        <UsersTable />
      </Container>
    </Section>
  );
};
