import React from "react";
import Meta from "components/Meta";
import UsersSection from "components/UsersSection";
import { requireAuth } from "util/auth";

function UsersPage(props) {
  return (
    <>
      <Meta title="Users" />
      <UsersSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Users"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(UsersPage, 'ADMIN');
