import queryConstructor from 'util/queryConstructor';
import { keepUnusedDataFor } from './constants';

function getUsers({ id, search } = {}) {
	const queryParams = queryConstructor({id, search});
	return `fetchUsers?${queryParams}`;
};

// Mutations
function updateUserOrganization({ userId, organizationId } = {}) {
	return {
		url: `updateOrganization`,
		method: 'POST',
		body: { userId, organizationId },
	};
};

export const handlers = (builder) => ({
	getUsers: builder.query({
		query: getUsers,
		keepUnusedDataFor: keepUnusedDataFor,
	}),

	// Mutations
	updateUserOrganization: builder.mutation({
		query: updateUserOrganization,
	}),
});

export default handlers;
