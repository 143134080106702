import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ArticlesTable from 'components/ArticlesTable';
import SpinnerCenter from 'components/SpinnerCenter';
import Toggle from 'components/Toggle';
import { useGetOrganizationPublicQuery } from 'api/client';
import { useParams } from 'util/router';
import InfoCard from 'components/Organization/InfoCard';
import KeywordsOrPersonasTable from 'components/Organization/KeywordsOrPersonasTable';
import './PublicOrganization.scss';

const TABS = ['articles', 'keywords', 'target personas'];

function PublicOrganization(props) {
	const { id } = useParams();
	const [activeTab, setActiveTab] = useState(TABS[0]);

	const {
  	data: organization = {},
  	isLoading: organizationIsLoading,
  	refetch: organizationRefetch,
  } = useGetOrganizationPublicQuery({ id });

	return organizationIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<div className="PublicOrganization">
	  	<Row>
				<Col xs={12} lg={4} xl={3}>
					<InfoCard
						{...(organization || {})}
						refetch={organizationRefetch}
					/>
				</Col>
				<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
					<Toggle
						ops={TABS}
						active={activeTab}
						setActive={op => setActiveTab(op)}
						className="w-fit mb-2"
					/>

					{activeTab === 'articles' ? (
						<ArticlesTable
							organizationId={organization.id}
						/>
					) : activeTab === 'keywords' ? (
						<KeywordsOrPersonasTable
							label="Keywords"
							items={organization.keywords}
							isLoading={organizationIsLoading}
						/>
					) : activeTab === 'target personas' ? (
						<KeywordsOrPersonasTable
							label="Target Personas"
							items={organization.targetPersonas}
							isLoading={organizationIsLoading}
						/>
					) : null}
				</Col>
			</Row>
		</div>
	);
};

export default PublicOrganization;
