import React, { useEffect } from 'react';
import classnames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'components/Spinner';
import { truncateString } from 'util/strings';
import {
	useUpdateUserOrganizationMutation,
} from 'api/client';

const MAX_LENGH_STR = 32;

export default function OrganizationRow(props) {
	const { user = {}, organizations = [], usersRefetch } = props;

	return (
		<tr>
			<td>
				<span>{truncateString(user?.name, MAX_LENGH_STR, '-')}</span>
			</td>
			<td title={user?.email || '-'}>
				<span>{truncateString(user?.email, MAX_LENGH_STR, '-')}</span>
			</td>
			<td title={user?.organization?.name || '-'}>
				{user?.role === 'ADMIN' ? (
					'-'
				) : (
					<OrganizationDropdown
						user={user}
						organizations={organizations}
						usersRefetch={usersRefetch}
					/>
				)}
			</td>
			<td title={user?.role}>
				<span>{truncateString(user?.role, MAX_LENGH_STR, '-')}</span>
			</td>
		</tr>
	);
};

const OrganizationDropdown = (props) => {
	const { user, organizations, usersRefetch } = props;

	// Mutations
  const [updateUserOrg, updateUserOrgResult] = useUpdateUserOrganizationMutation();

  useEffect(() => {
		if (updateUserOrgResult.isError) {
			console.error('useUpdateUserOrganizationMutation error', updateUserOrgResult.error);
		}
		if (updateUserOrgResult.isSuccess) {
			usersRefetch();
			updateUserOrgResult.reset();
		}
	}, [
		updateUserOrgResult,
		usersRefetch,
	]);

	return (
		<Dropdown>
		  <Dropdown.Toggle
		  	variant={classnames({
		  		'outline-primary': user?.organization?.name,
		  		'gray-100': !user?.organization?.name
		  	})}
		  	size="sm"
		  	id="dropdown-basic"
		  >
		    {updateUserOrgResult?.isLoading ? (<Spinner className="text-muted mr-2" />) : user.organization?.name || 'Asign org'}
		  </Dropdown.Toggle>

		  <Dropdown.Menu>
		  	{organizations.map(
		  		org => (
		  			<Dropdown.Item
		    			key={org.id}
		    			active={user.organization?.name === org.name}
		    			className="text-capitalize"
		    			onSelect={() => {
		    				if (!updateUserOrgResult?.isLoading) {
		    					updateUserOrg({ userId: user.id, organizationId: org.id });
		    				}
		    			}}
		    		>
		    			{org.name}
		    		</Dropdown.Item>
		  		)
		  	)}
		  </Dropdown.Menu>
		</Dropdown>
	);
};
