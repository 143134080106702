import queryConstructor from 'util/queryConstructor';
import { keepUnusedDataFor } from './constants';

function getArticles({ id, organizationId, search } = {}) {
	const queryParams = queryConstructor({id, organizationId, search});
	return `fetchArticles?${queryParams}`;
};

// Mutations
function createBlankArticle({ organizationId } = {}) {
	return {
		url: `createBlankArticle`,
		method: 'POST',
		body: { organizationId },
	};
};

function removeArticle({ id } = {}) {
	return {
		url: `removeArticle`,
		method: 'POST',
		body: { id },
	};
};

function updateArticle(updateInfo = {}) {
	return {
		url: `updateArticle`,
		method: 'POST',
		body: updateInfo,
	}
};

function generateArticleTitleOptions(generateInfo = {}) {
	return {
		url: `generateTitleOptions`,
		method: 'POST',
		body: generateInfo,
	}
};

function generateArticle(generateInfo = {}) {
	return {
		url: `generateArticle`,
		method: 'POST',
		body: generateInfo,
	};
};

// Handlers
export const handlers = (builder) => ({
	getArticles: builder.query({
		query: getArticles,
		keepUnusedDataFor: keepUnusedDataFor,
	}),

	// Mutations
	createBlankArticle: builder.mutation({
		query: createBlankArticle,
	}),

	removeArticle: builder.mutation({
		query: removeArticle,
	}),

	updateArticle: builder.mutation({
		query: updateArticle,
	}),

	generateArticleTitleOptions: builder.mutation({
		query: generateArticleTitleOptions,
	}),

	generateArticle: builder.mutation({
		query: generateArticle,
	}),
});

export default handlers;
