import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import articlesHandlers from './articles';
import organizationsHandlers from './organizations';
import usersHandlers from './users';
import { apiUri, prepareHeadersForAuth } from './constants';

// Articles
export const articlesApiSlice = createApi({
	reducerPath: 'articles',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/articles`,
		prepareHeaders: prepareHeadersForAuth,
	}),
	endpoints: builder => articlesHandlers(builder),
});

export const {
	// Queries
	useGetArticlesQuery,
	// Mutations
	useCreateBlankArticleMutation,
	useRemoveArticleMutation,
	useUpdateArticleMutation,
	useGenerateArticleTitleOptionsMutation,
	useGenerateArticleMutation,
} = articlesApiSlice;

// Organizations
export const organizationsApiSlice = createApi({
	reducerPath: 'organizations',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/organizations`,
		prepareHeaders: prepareHeadersForAuth,
	}),
	endpoints: builder => organizationsHandlers(builder),
});

export const {
	// Queries
	useGetOrganizationsQuery,
	useGetOrganizationPublicQuery,
	// Mutations
	useUpsertOrganizationMutation,
	useRemoveOrganizationMutation,
} = organizationsApiSlice;


// Users
export const usersApiSlice = createApi({
	reducerPath: 'users',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/users`,
		prepareHeaders: prepareHeadersForAuth,
	}),
	endpoints: builder => usersHandlers(builder),
});

export const {
	// Queries
	useGetUsersQuery,
	// Mutations
	useUpdateUserOrganizationMutation,
} = usersApiSlice;
