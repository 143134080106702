import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterList } from '@fortawesome/pro-solid-svg-icons';
import { STATUS_MAP } from 'components/Article/constants';

export default function Filter(props) {
	const { statusFilter, setStatusFilter } = props;

	return (
		<Dropdown className="ml-2">
		  <Dropdown.Toggle
		  	variant="outline-info"
		  	id="dropdown-basic"
		  >
		    <FontAwesomeIcon icon={faFilterList} />
		  </Dropdown.Toggle>

		  <Dropdown.Menu>
			  {Object.entries(STATUS_MAP).map(
			  	([key, value]) => (
		    		<Dropdown.Item
		    			key={key}
		    			active={statusFilter.includes(value)}
		    			className="text-capitalize"
		    			onSelect={() => {
			    			setStatusFilter(
			    				prev => prev.includes(value) ? prev.filter(o => o !== value)
			    					: [...prev, value]
			    			);
		    			}}
		    		>
		    			{value}
		    		</Dropdown.Item>
			  	)
			  )}
		  </Dropdown.Menu>
		</Dropdown>
	);
};
