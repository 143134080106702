import React from "react";
import { Redirect } from "./../util/router";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  const auth = useAuth();
  const router = useRouter();

  return auth.user ? (<Redirect push to="/organizations"/>) : (
    <>
      <Meta title="Auth" />
      <AuthSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google"]}
        afterAuthPath={router.query.next || "/auth/signin"}
      />
    </>
  );
}

export default AuthPage;
