import React, { useCallback } from 'react';
import classnames from 'classnames';
import Flex from 'components/Flex';
import './Toggle.scss';

function Toggle(props) {
	const {
		active,
		setActive,
		ops = [],
		isOpActive,
		className = "",
		label,
		labelClassName = "",
		opClassName = "",
		activeClassName = "",
		disabled,
		onOpClick: _onOpClick,
		...toggleProps
	} = props;

	const onOpClick = useCallback(
		(op) => typeof _onOpClick === 'function' ? _onOpClick(op) : null,
		[_onOpClick]
	);

	return (
		<>
			{label && (
				<span className={labelClassName}>{label}</span>
			)}
			<Flex
				className={`Toggle ${className}`.trim()}
				{...toggleProps}
			>
				{ops.map((op, idx) => (
					<Option
						key={idx}
						idx={idx}
						active={active}
						setActive={setActive}
						activeClassName={activeClassName}
						op={op}
						opClassName={opClassName}
						isOpActive={isOpActive}
						disabled={disabled}
						onOpClick={onOpClick}
					/>
				))}
				{props.children}
			</Flex>
		</>
	);
};

export default Toggle;

const Option = ({ active, setActive, activeClassName, op, opClassName, isOpActive, disabled, onOpClick }) => {
	const isActive = typeof isOpActive === 'function' ? isOpActive(active, op)
		: Array.isArray(active) ? active.includes(op)
			: typeof active === 'function' ? active(op)
				: active === op;

	return (
		<div
			className={classnames('Option', {
				'active': isActive,
				...opClassName ? {
					[opClassName]: true,
				} : {},
				...activeClassName ? {
					[activeClassName]: isActive
				} : {},
				'disabled': disabled,
			})}
			onClick={(e) => {
				if (!disabled) {
					typeof setActive === 'function' && setActive(op, e);
					onOpClick(op, e);
				}
			}}
		>
			{op}
		</div>
	);
};
