import React from "react";
import "styles/global.scss";
import { Switch, Route, Router, Redirect } from "util/router";
import { AuthProvider } from "util/auth";
import { QueryClientProvider } from "util/db";
import NavbarCustom from "components/NavbarCustom";
import Footer from "components/Footer";
import ArticlesPage from "pages/articles";
import ArticlePage from "pages/article";
import AuthPage from "pages/auth";
import DashboardPage from "pages/dashboard";
import OrganizationsPage from "pages/organizations";
import OrganizationPage from "pages/organization";
import PublicOrganizationPage from "pages/publicOrganization";
import UsersPage from "pages/users";
import UserPage from "pages/user";
import NotFoundPage from "pages/404";

const logo = "/img/logo-navbar.svg";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo={logo}
            />

            <Switch>
              <Route exact path="/">
                <Redirect to="/auth/signin" />
              </Route>
              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/articles" component={ArticlesPage} />
              <Route exact path="/articles/:id" component={ArticlePage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route exact path="/organizations" component={OrganizationsPage} />
              <Route exact path="/organizations/:id" component={OrganizationPage} />

              <Route exact path="/public/organization/:id" component={PublicOrganizationPage} />

              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/users/:userId" component={UserPage} />
              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="light"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              copyright={`© ${new Date().getFullYear()} Squirrels AI`}
              logo={logo}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
