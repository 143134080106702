export const titleGeneratorReducerInitialState = {
	title: '',
	keywords: [],
	targetPersonas: [],
	extraNotes: ''
};

export function titleGeneratorReducer(state, action) {
	let returnState = {};

	switch(action.type) {
		case 'title':
			returnState = {
				...state,
				title: action.payload,
			};
			break;
		case 'keywords':
			returnState = {
				...state,
				keywords: state.keywords.includes(action.payload) ? state.keywords.filter(o => o !== action.payload)
					: [...state.keywords, action.payload]
			};
			break;
		case 'targetPersonas':
			returnState = {
				...state,
				targetPersonas: state.targetPersonas.includes(action.payload) ? state.targetPersonas.filter(o => o !== action.payload)
					: [...state.targetPersonas, action.payload]
			};
			break;
		case 'extraNotes':
			returnState = {
				...state,
				extraNotes: action.payload,
			};
			break;
		default:
			returnState = state;
			break;
	}

	return returnState;
};
