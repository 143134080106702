import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Flex from 'components/Flex';

function SpinnerCenter(props) {
	return (
		<Flex
			justify="center"
			className={props.wrapperClassName || ''}
		>
			<Spinner
				animation={props.animation || 'border'}
				variant={props.variant || 'primary'}
				size={props.size || ''}
				className={props.className || ''}
			/>
		</Flex>
	);
};

export default SpinnerCenter;
