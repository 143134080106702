import React from 'react';
import Container from 'react-bootstrap/Container';
import Section from 'components/Section';
import SectionHeader from "components/SectionHeader";
import PublicOrganization from 'components/PublicOrganization';
import Spinner from 'components/Spinner';
import { useGetOrganizationsQuery } from 'api/client';
import { useParams } from 'util/router';

function PublicOrganizationSection(props) {
  const { id } = useParams();

  const {
    data: organizations = [],
    isLoading: organizationsIsLoading,
  } = useGetOrganizationsQuery({ id });

  const [organization = {}] = organizations;

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid style={{maxWidth: '1400px'}}>
        <SectionHeader
          title={organizationsIsLoading ? (
            <span>Org: {<Spinner className="f-rem-1.25" />}</span>
          ) : (
            <span>{organization.name ? `Org: ${organization.name}` : 'Organization'}</span>
          )}
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        />
        <PublicOrganization />
      </Container>
    </Section>
  );
}

export default PublicOrganizationSection;
