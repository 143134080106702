import React, { forwardRef } from 'react';

function Flex(props, ref) {
	const { direction, wrap, justify, align, grow, shrink, className, style = {}, ...rest } = props;

	const joinedClass = [
		'Flex d-flex',
		direction ? `flex-${direction}` : null,
		wrap ? `flex-${wrap}` : null,
		justify ? `justify-content-${justify}` : null,
		align ? `align-items-${align}` : null,
		grow ? `flex-grow-${grow}` : null,
		shrink ? `flex-shrink-${shrink}` : null,
		className || null,
	].filter(o => !!o).join(' ');

	return (
		<div
			{...ref ? { ref } : {}}
			className={joinedClass}
			style={style}
			{...rest}
		>
			{props.children}
		</div>
	);
}

export default forwardRef(Flex);
