import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'components/Spinner';
import StatusBadge from 'components/Article/StatusBadge';
import { useRemoveArticleMutation } from 'api/client';
import { commaFormat } from 'util/numbers';
import { truncateString } from 'util/strings';

function ArticleRow(props) {
	const { articlesRefetch } = props;

	// Mutations
  const [removeArticle, removeArticleResult] = useRemoveArticleMutation();

  useEffect(() => {
		if (removeArticleResult.isError) {
			console.error('useRemoveArticleMutation error', removeArticleResult.error);
		}

		if (removeArticleResult.isSuccess) {
			articlesRefetch();
		}
	}, [
		removeArticleResult,
		articlesRefetch,
	]);

	return (
		<tr
			onClick={() => props.navigateToArticle(props.id)}
		>
			{/*Article Title*/}
			<td>
				<span>{truncateString(props.title, 52, '-')}</span>
			</td>
			{/*Org name*/}
			<td>
				<Button
					variant="link"
					className="p-0"
					onClick={(e) => {
						e.stopPropagation();
						props.navigateToOrganization(props.organization?.id);
					}}
				>
					{truncateString(props.organization?.name, 32, '-')}
				</Button>
			</td>
			{/*Article Type*/}
			<td>
				<span>{truncateString(props.type, 32, '-')}</span>
			</td>
			{/*Word Count*/}
			<td>
				<span>{commaFormat(props.wordCount, undefined, '-')}</span>
			</td>
			{/*Status*/}
			<td>
				<StatusBadge status={props.status} />
			</td>
			{/*Actions*/}
			<td>
				<Button
					title="Remove article"
					variant="outline-danger"
					size="sm"
					className="py-0"
					disabled={removeArticleResult.isLoading}
					onClick={e => {
						e.stopPropagation();

						if (
							window.confirm('Are you sure? This cannot be undone.')
						) {
							removeArticle({ id: props.id });
						}
					}}
				>
					{removeArticleResult.isLoading ? <Spinner /> : <FontAwesomeIcon icon={faTrashCan} />}
				</Button>
			</td>
		</tr>
	);
};

export default ArticleRow;
