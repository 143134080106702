import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import SpinnerCenter from 'components/SpinnerCenter';
import { commaFormat } from 'util/numbers';
import { truncateString } from 'util/strings';

const MAX_LENGH_STR = 48;

function KeywordsOrPersonasTable(props) {
	return (
		<Card className={`KeywordsOrPersonasTable shadow ${props.className}`.trim()}>
			<Card.Header>
				<span>{props.label} ({commaFormat(props.items?.length, undefined, '0')})</span>
			</Card.Header>
			<Card.Body className="p-0 scroll-x">
				{props.isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : props.items?.length > 0 ? (
					<Table size="sm" className="responsive table-stripe">
						<thead>
							<tr>
								<th>Title</th>
								<th>Description</th>
								{/*<th>Actions</th>*/}
							</tr>
						</thead>
						<tbody>
							{props.items?.map(item => (
								<tr key={item.id}>
									<td>
										<span>{truncateString(item.title, MAX_LENGH_STR, '-')}</span>
									</td>
									<td>
										<span>{truncateString(item.description, MAX_LENGH_STR, '-')}</span>
									</td>
									{/*<td></td>*/}
								</tr>
							))}
						</tbody>
					</Table>
				) : (
					<Flex justify="center" className="text-danger py-2">
						<span>No {props.label} found</span>
					</Flex>
				)}
			</Card.Body>
		</Card>
	);
};

export default KeywordsOrPersonasTable;
