import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';

function OverwriteTitle(props) {
	const {
		title,
		overwriteTitle,
		updateArticleResult,
		generateTitleOptionsResult,
		dispatch,
		updateArticle,
	} = props;

	const isLoading = updateArticleResult.isLoading || generateTitleOptionsResult.isLoading;
	const isSuccess = updateArticleResult.isSuccess || generateTitleOptionsResult.isSuccess;
	const isError = updateArticleResult.isError || generateTitleOptionsResult.isError;

	const disabled = !overwriteTitle ||
		(title && title === overwriteTitle) ||
		isLoading ||
		isError ||
		isSuccess;

	return (
		<Flex direction="column">
			<Form.Group className="mb-2">
				<Form.Label className="label mb-0.5">Enter your own title manually</Form.Label>
				<Form.Control
					type="text"
					placeholder="Enter your own title manually"
					value={overwriteTitle}
					readOnly={isLoading || isError || isSuccess}
					onChange={e => dispatch(e.target.value)}
				/>
			</Form.Group>
			<Button
				variant={updateArticleResult.isSuccess ? 'success' : updateArticleResult.isError ? 'danger' : 'primary'}
				size="sm"
				disabled={disabled}
				onClick={() => updateArticle({ id: props.id, title: overwriteTitle })}
				className="w-fit"
			>
				{updateArticleResult.isLoading ? (
					<Spinner />
				) : updateArticleResult.isSuccess ? (
					<FontAwesomeIcon icon={faCheck} />
				) : updateArticleResult.isError ? (
					<FontAwesomeIcon icon={faTimes} />
				) : 'Save'}
			</Button>
		</Flex>
	);
};

export default OverwriteTitle;
