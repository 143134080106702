import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import { useUpdateArticleMutation } from 'api/client';
import { STATUS_MAP, STATUS_COLORS } from 'components/Article/constants';
import './styles.scss';

function StatusBadge(props) {
	const { isEditable, articleId, status, refetch } = props;
	const [show, setShow] = useState(false);
	const [updateStatus, updateStatusResult] = useUpdateArticleMutation();

	useEffect(() => {
		const { error, isError, isSuccess } = updateStatusResult;
		if (isError) {
			console.error('useUpdateArticleMutation error', error);
			setTimeout(() => updateStatusResult.reset(), 750);
		}

		if (isSuccess) {
			typeof refetch === 'function' && refetch();

			setTimeout(() => {
				updateStatusResult.reset();
				setShow(false);
			}, 750);
		}
	}, [
		updateStatus,
		updateStatusResult,
		refetch,
	]);

	return isEditable && articleId ? (
		<DropdownButton
			id="status-selector"
			title={capitalizeFirstLetterOfEachWord(props.status || '-')}
			size="sm"
			variant={STATUS_COLORS[props.status] || 'secondary'}
			show={show}
			onClick={() => setShow(prev => !prev)}
			className={`StatusBadge isEditable ${props.className}`.trim()}
		>
			{Object.values(STATUS_MAP).map(s => (
				<Dropdown.Item
					key={s}
					active={s === status}
					disabled={updateStatusResult.isLoading}
					onClick={e => {
						e.stopPropagation();

						if (!updateStatusResult.isLoading && s !== status) {
							updateStatus({ id: articleId, status: s });
						}
					}}
				>
					<Flex
						justify="between"
						align="center"
					>
						<span>{s}</span>
						{updateStatusResult?.originalArgs?.status !== s ? null : updateStatusResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} className="text-success" />
						) : updateStatusResult.isError ? (
							<FontAwesomeIcon icon={faTimes} className="text-danger" />
						) : (
							<Spinner className="ml-3" />
						)}
					</Flex>
				</Dropdown.Item>
			))}
		</DropdownButton>
	) : (
		<Badge
			variant={STATUS_COLORS[props.status] || 'secondary'}
			className={`StatusBadge ${props.className}`.trim()}
		>
			<span className="simple-badge">{props.status || '-'}</span>
		</Badge>
	);
};

export default StatusBadge;

const capitalizeFirstLetterOfEachWord = (word) => word.split(' ').map(
	str => `${str[0].toUpperCase()}${str.slice(1)}`
).join(' ');
