import React from 'react';
import Flex from 'components/Flex';
import FormField from 'components/FormFieldCustom';

function FormMultiField(props) {
	return props.items.length > 0 ? props.items.map((item, idx) => (
		<Flex
			key={idx}
			direction="column"
			className="border rounded p-2 mt-3"
		>
			<FormField
				id={`${props.type}-title-${item.id}`}
				label={`${props.label} Title`}
				type="text"
				placeholder={`${props.label} title`}
				defaultValue={item.title}
				required={true}
				onChange={props.onChange}
				readOnly={props.isReadOnly}
				invalidFeedback={`${props.label} title cannot be empty`}
				remove={() => props.removeItem(item.id)}
			/>

			<FormField
				id={`${props.type}-description-${item.id}`}
				label={`${props.label} Description`}
				type="textarea"
				placeholder={`${props.label} description`}
				defaultValue={item.description}
				onChange={props.onChange}
				readOnly={props.isReadOnly}
				invalidFeedback={`${props.label} description cannot be empty`}
			/>
		</Flex>
	)) : (
		<div className="text-warning text-center">No {props.label}s</div>
	);
};

export default FormMultiField;
