import React from "react";
import Meta from "components/Meta";
import DashboardSection from "components/DashboardSection";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bg="light"
        textColor="dark"
        size="sm"
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default DashboardPage;
