export function keywordsAndPersonasReducer(state, action) {
	let returnState;

	switch(action.type) {
		case 'PUSH':
			returnState = [...state, action.payload];
			break;
		case 'REMOVE':
			returnState = state.filter(o => o.id !== action.payload);
			break;
		default:
			returnState = state;
			break;
	}

	return returnState;
};
